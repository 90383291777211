import React from 'react';
import { To, Link, parsePath, resolvePath } from 'react-router-dom';
import { ExtraProps } from 'react-markdown';
import { DocumentationMdComponent, DocumentationMdProps } from '../MarkdownDocumentation';

export function AComponent({
  node,
  mdPage,
  docEndpoint,
  href,
  children,
  ...props
}: React.ClassAttributes<HTMLAnchorElement> &
  React.AnchorHTMLAttributes<HTMLAnchorElement> &
  ExtraProps &
  DocumentationMdProps): DocumentationMdComponent {
  if (!href) {
    return (
      <a href={href} {...props}>
        {children}
      </a>
    );
  }

  // External link, open a new tab
  if (href.startsWith('http')) {
    return (
      <a href={href} target="_blank" rel="noreferrer" {...props}>
        {children}
      </a>
    );
  }

  // Anchor tag or mail address, keep unchanged
  if (href.startsWith('#') || href.startsWith('mailto:')) {
    return (
      <a href={href} {...props}>
        {children}
      </a>
    );
  }

  // Path is relative to the current location, parse it to a Path obj
  const path = parsePath('../' + href);

  // File link (.pdf, .sh, .py), open a new tab
  if (mdPage.filePath && !path.pathname?.endsWith('.md') && !path.pathname?.endsWith('.mdp')) {
    const apiUrl = new URL(docEndpoint, window.CONFIG.apiServerBaseUrl).toString();
    href = resolveFilePath(path, `${apiUrl}/${mdPage.filePath}`);

    return (
      <a href={href} target="_blank" rel="noreferrer" {...props}>
        {children}
      </a>
    );
  }

  // Intra-doc link, use React navigation
  href = resolveFilePath(path, mdPage.location.pathname);
  return <Link to={href}>{children}</Link>;
}

function resolveFilePath(to: To, fromPathname?: string | undefined) {
  const path = resolvePath(to, fromPathname);
  return path.pathname + path.hash + path.search;
}
