/* eslint-disable no-nested-ternary */

import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DownOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Alert, Button, Divider, Dropdown, Input, Popconfirm, Popover, Space, Tooltip } from 'antd';

import { ItemType } from 'antd/lib/menu/interface';
import { ColumnsType, ColumnType } from 'antd/lib/table/interface';

import { statusToPillType } from '../../../../pages/shared/instance-mapping';
import { ResourceStatus, UserDefinedTableOverview } from '../../../api/user-data-lake-api';
import { SorterFactory } from '../../../pages/data-query/components/outputs/_shared/table-utils';
import { DpActivityJobStatusFile, DpActivityJobStatusTable, DpActivityWorkflow, DpActivityWorkflowStep } from '../../../pages/types';

import { createCommonDateFormatter, humanFileSize } from '../../../utils/text-utils';

import ColumnStatusRenderer from './ColumnStatusRenderer';
import EnhancedStatusRenderer from './EnhancedStatusRenderer';

import classes from '../DpActivity.module.css';
import styles from './CreateColumns.module.css';

const MENU_KEYS = {
  VIEW_JOB: 'viewJob',
  CLONE: 'clone',
  SHOW_RELATED: 'showRelated',
  DELETE: 'delete',
  DUMP_AUDIT: 'dumpAudit',
};

const dateFormatter = createCommonDateFormatter();

const noDataDateFormatter = createCommonDateFormatter('No Data', 'No Data');

const renderPreviewName = (id: string) => {
  const partOfId = id.split('-')[0];

  return (
    <Tooltip title={id}>
      <span className={classes.infoText}>{partOfId}</span>
    </Tooltip>
  );
};

const renderMaybeName = (id: string, { formData }: DpActivityJobStatusFile) => {
  const localName = formData?.file?.name;

  return (
    <Tooltip title={id}>
      <span className={classes.infoText}>{localName || id.split('-')[0]}</span>
    </Tooltip>
  );
};

export const initColumnsForJobsData = (
  handleShowWorkflowInfo: (id: string) => void,
  handleWorkflowItemClone: (id: string) => void,
  handleDeleteJob: (id: string) => void,
  handleShowRelatedItemsInfo: boolean | ((id: string[]) => void),
  handleCreateAuditData: (id: string) => void,
  sorterFactory?: SorterFactory
): ColumnsType<DpActivityWorkflow> => [
  {
    title: 'Job ID',
    dataIndex: 'id',
    width: '100px',
    render: renderPreviewName,
    ...sorterFactory?.('id'),
  },
  {
    title: 'Current Task',
    dataIndex: 'currentJobName',
    width: '180px',
    render: (value: string) => value || '(None)',
    ...sorterFactory?.('currentJobName'),
  },
  {
    title: 'Status',
    dataIndex: 'currentStatus',
    render: EnhancedStatusRenderer,
    ...sorterFactory?.('currentStatus'),
  },
  {
    title: 'Started at',
    dataIndex: 'createdAt',
    width: '180px',
    render: dateFormatter,
    ...sorterFactory?.('createdAt', true),
  },
  {
    title: 'Last Update',
    dataIndex: 'updatedAt',
    width: '180px',
    render: dateFormatter,
    ...sorterFactory?.('updatedAt', true),
  },
  {
    title: 'Actions',
    key: 'actions',
    fixed: 'right',
    align: 'center',
    width: '100px',
    render: (text: string, record: DpActivityWorkflow) => {
      const handleMenuClick = ({ key }: { key: string }) => {
        if (key === MENU_KEYS.VIEW_JOB) {
          handleShowWorkflowInfo(record.id);
          return;
        }

        if (key === MENU_KEYS.CLONE) {
          handleWorkflowItemClone(record.id);
          return;
        }

        if (key === MENU_KEYS.SHOW_RELATED && typeof handleShowRelatedItemsInfo === 'function') {
          handleShowRelatedItemsInfo([record.id, ...record.workflowAssociatedIds]);
          return;
        }

        if (key === MENU_KEYS.DELETE) {
          handleDeleteJob(record.id);
          return;
        }

        if (key === MENU_KEYS.DUMP_AUDIT) {
          handleCreateAuditData(record.id);
        }
      };

      const incorrectState = false;
      const menuItems: ItemType[] = [
        { key: MENU_KEYS.VIEW_JOB, label: 'View Job Info', disabled: !handleShowWorkflowInfo },
        { key: MENU_KEYS.CLONE, label: 'Clone', disabled: !handleWorkflowItemClone },
        { type: 'divider' },
        { key: MENU_KEYS.SHOW_RELATED, label: 'Only show Related Items', disabled: !handleShowRelatedItemsInfo },
        { type: 'divider' },
        { key: MENU_KEYS.DUMP_AUDIT, label: 'Dump Audit Data' },
      ];

      const menu = { onClick: handleMenuClick, items: menuItems };

      return (
        <Space>
          <Dropdown trigger={['click']} menu={menu}>
            <Button size="small">
              Actions <DownOutlined />
            </Button>
          </Dropdown>
          <Popconfirm
            title="Are you sure you want to delete this job?"
            onConfirm={() => handleDeleteJob(record.id)}
            okText="Yes"
            cancelText="No"
            placement="bottomRight"
            disabled={!handleDeleteJob || incorrectState}
          >
            <Button size="small" disabled={!handleDeleteJob || incorrectState}>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      );
    },
  },
];

export const initTableDataColumns = (
  handleTableDelete: (id: string) => Promise<void>,
  handleViewDataClick: (record: DpActivityJobStatusTable) => void,
  handleShowRelatedItemsInfo: boolean | ((id: string) => void),
  sorterFactory?: SorterFactory
): ColumnsType<DpActivityJobStatusTable> => [
  {
    title: 'Table Name',
    dataIndex: 'displayName',
    render: (displayName: string, { id, createdAt }: DpActivityJobStatusTable) => {
      const name = displayName || id.split('-')[0];
      const createdDateTime = dateFormatter(createdAt);

      return (
        <div>
          <Tooltip title={id}>
            <span className={classes.infoText}>{name}</span>
          </Tooltip>
          <Tooltip placement="topLeft" title={`Created at ${createdDateTime}`}>
            <InfoCircleOutlined style={{ fontSize: 12 }} />
          </Tooltip>
        </div>
      );
    },
    ...sorterFactory?.('displayName'),
  },
  {
    title: 'Shared',
    dataIndex: 'isPublic',
    align: 'center',
    width: '100px',
    render: (isPublic: boolean) =>
      isPublic ? (
        <Tooltip placement="left" title="Public">
          <TeamOutlined />
        </Tooltip>
      ) : (
        <Tooltip placement="left" title="Private">
          <UserOutlined />
        </Tooltip>
      ),
    ...sorterFactory?.('isPublic'),
  },
  {
    title: 'Source',
    dataIndex: 'source',
    width: '100px',
    ...sorterFactory?.('source'),
  },
  {
    title: 'Created By',
    dataIndex: 'createdBy',
    width: '180px',
    ...sorterFactory?.('createdBy'),
  },
  {
    title: 'Updated at',
    dataIndex: 'updatedAt',
    width: '180px',
    render: dateFormatter,
    ...sorterFactory?.('updatedAt'),
  },
  {
    title: 'Actions',
    key: 'actions',
    fixed: 'right',
    align: 'center',
    width: '200px',
    render: (text: string, record: DpActivityJobStatusTable) => {
      const { id, isPublic } = record;
      const message = isPublic ? (
        <p>This table is shared with the rest of the SEC. Are you sure you want to delete it?</p>
      ) : (
        <p>Are you sure you want to to delete this table?</p>
      );

      return (
        <Space>
          <Button size="small" disabled={!handleViewDataClick} onClick={() => handleViewDataClick(record)}>
            View Data
          </Button>
          <Popconfirm title={message} onConfirm={() => handleTableDelete(id)} okText="Yes" cancelText="No" placement="bottomRight">
            <Button size="small" disabled={!handleTableDelete}>
              Delete
            </Button>
          </Popconfirm>
          <Button
            size="small"
            disabled={!handleShowRelatedItemsInfo}
            onClick={() => {
              if (typeof handleShowRelatedItemsInfo === 'function') {
                const workflowId = id.match(/^wf_(.+)_(input|output)$/)?.[1] ?? id;
                handleShowRelatedItemsInfo(workflowId);
              }
            }}
          >
            Related Items
          </Button>
        </Space>
      );
    },
  },
];

export const initFileUploadColumns = (
  handleFileUploadDelete?: (id: string) => void,
  handleFileUploadDownload?: (id: string) => void,
  handleShowRelatedItemsInfo?: boolean | ((id: string) => void),
  deletingId?: string,
  sorterFactory?: SorterFactory
): ColumnsType<DpActivityJobStatusFile> => [
  {
    title: 'File ID',
    dataIndex: 'id',
    render: renderMaybeName,
    ...sorterFactory?.('id'),
  },
  {
    title: 'File Type',
    dataIndex: 'formData.file.type',
    width: '150px',
    render: (_: unknown, { formData }: DpActivityJobStatusFile) => (
      <div className={classes.ellipsis}>{formData?.file?.type || '(Unknown)'}</div>
    ),
    ...sorterFactory?.('formData.file.type'),
  },
  {
    title: 'File Size',
    dataIndex: 'formData.file.size',
    width: '100px',
    render: (_: unknown, { formData }: DpActivityJobStatusFile) =>
      formData?.file?.size ? humanFileSize(formData?.file?.size) : '(Unknown)',
    ...sorterFactory?.('formData.file.size'),
  },
  {
    title: 'Source',
    dataIndex: 'generatedBy',
    width: '150px',
    render: (generatedBy: string) =>
      generatedBy === 'DP_S3_UPLOAD' ? (
        <Space>
          <CloudUploadOutlined />
          User Upload
        </Space>
      ) : generatedBy === 'DP_WORKFLOW' ? (
        <Space>
          <CloudDownloadOutlined />
          Workflow Engine
        </Space>
      ) : (
        generatedBy
      ),
    ...sorterFactory?.('generatedBy'),
  },
  {
    title: 'Status',
    dataIndex: 'currentState',
    width: '200px',
    render: ColumnStatusRenderer,
    ...sorterFactory?.('currentState'),
  },
  {
    title: 'Created By',
    dataIndex: 'createdBy',
    width: '100px',
    ...sorterFactory?.('createdBy'),
  },
  {
    title: 'Started at',
    dataIndex: 'createdAt',
    width: '180px',
    render: dateFormatter,
    ...sorterFactory?.('createdAt'),
  },
  {
    title: 'Actions',
    key: 'actions',
    fixed: 'right',
    align: 'center',
    width: '200px',
    render: (text: string, record: DpActivityJobStatusFile) => {
      const { id, currentState, associatedWorkflowId } = record;

      const fileDeleted = currentState === 'FILE_DELETED';
      const deleteDisabled = deletingId === id || fileDeleted;

      return (
        <Space>
          <Button size="small" disabled={fileDeleted} onClick={() => handleFileUploadDownload?.(id)}>
            Download
          </Button>

          <Popconfirm
            title="Are you sure you want to delete this file upload?"
            onConfirm={() => handleFileUploadDelete?.(record.id)}
            okText="Yes"
            cancelText="No"
            placement="bottomRight"
            disabled={deleteDisabled}
          >
            <Button size="small" disabled={deleteDisabled}>
              Delete
            </Button>
          </Popconfirm>
          {window.CONFIG.isMidasPeer && (
            <Button
              size="small"
              disabled={!handleShowRelatedItemsInfo}
              onClick={() => typeof handleShowRelatedItemsInfo === 'function' && handleShowRelatedItemsInfo(associatedWorkflowId)}
            >
              Related Items
            </Button>
          )}
        </Space>
      );
    },
  },
];

export const initColumnsForWorkflowControl = (): ColumnType<DpActivityWorkflowStep>[] => [
  {
    title: 'Task Name',
    dataIndex: 'actionDisplayName',
    width: '100px',
  },
  {
    title: 'Status',
    dataIndex: 'processingStatus',
    width: '300px',
    render: EnhancedStatusRenderer,
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    width: '80px',
    render: noDataDateFormatter,
  },
  {
    title: 'Raw Output Data',
    dataIndex: 'outputData',
    width: '80px',
    render: (outputData: { errors: string[] } | undefined) => {
      const items = outputData?.errors?.map((error, index) => (
        <>
          {index ? <Divider /> : null} {error}
        </>
      ));

      return (
        <Popover
          title="Raw Output Data"
          content={<div className={classes.errorStatement}>{items}</div>}
          trigger="click"
          placement="bottomRight"
          open={!outputData ? false : undefined}
        >
          <Button disabled={!outputData}>View</Button>
        </Popover>
      );
    },
  },
];

export const initUDTColumns = (
  handleTableDelete: (id: string) => Promise<void>,
  handleModifyPermissionsClick: (id: string, canShare: boolean) => void,
  handleRename: (id: string, name: string) => void,
  sorterFactory?: SorterFactory
): ColumnsType<UserDefinedTableOverview> => [
  {
    title: 'Table Name',
    dataIndex: 'name',
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div style={{ padding: 8 }}>
        <Input
          value={selectedKeys[0]}
          type="primary"
          style={{ marginBottom: 8, display: 'block' }}
          onPressEnter={() => confirm()}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        />
        <Space>
          <Button size="small" style={{ width: 90 }} icon={<SearchOutlined />} onClick={() => confirm()}>
            Search
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              confirm();
              close();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            onClick={() => {
              close();
            }}
            size="small"
            type="link"
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      record['name']
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    ...sorterFactory?.('name'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: (status: ResourceStatus) => (
      <div className={styles.UDTStatusPillContainer}>
        <Alert type={statusToPillType[status]} message={status} className={styles.UDTStatusPill} />
      </div>
    ),
    ...sorterFactory?.('status'),
  },
  {
    title: 'Created by',
    dataIndex: 'createdBy',
    align: 'center',
  },
  {
    title: 'Shared with',
    dataIndex: 'users',
    align: 'center',
    render: (users: string[], { isPrivate }: UserDefinedTableOverview) => {
      if (!isPrivate) {
        return `Everyone`;
      } else {
        return `${users.length} users`;
      }
    },
  },
  {
    title: 'Permissions',
    dataIndex: 'access',
    align: 'center',
  },
  {
    title: 'Actions',
    key: 'actions',
    fixed: 'right',
    align: 'center',
    width: '150px',
    render: (_text: string, record: UserDefinedTableOverview) => {
      const { id, owned, status, name } = record;
      const deleteDisabled = !owned || !['PROVISIONED', 'PROVISIONING', 'PROVISIONING_ERROR'].includes(status);
      const modifyPermissionsVisible = record.canShare;
      const renameVisible = owned;
      const modifyPermissionsDisabled = !['PROVISIONED', 'PROVISIONING'].includes(status);
      const renameDisabled = !owned || !['PROVISIONED', 'PROVISIONING'].includes(status);

      return (
        <Space>
          <Popconfirm
            title={'Are you sure you want to delete this table?'}
            onConfirm={() => handleTableDelete(id)}
            okText="Yes"
            cancelText="No"
            placement="bottomRight"
          >
            <Button size="small" disabled={deleteDisabled}>
              Delete
            </Button>
          </Popconfirm>
          {modifyPermissionsVisible && (
            <Button size="small" disabled={modifyPermissionsDisabled} onClick={() => handleModifyPermissionsClick(id, record.canShare)}>
              Permissions
            </Button>
          )}
          {renameVisible && (
            <Button size="small" disabled={renameDisabled} onClick={() => handleRename(id, name)}>
              Rename
            </Button>
          )}
        </Space>
      );
    },
  },
];
