import React, { useCallback, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Space, Tabs, TabsProps } from 'antd';

import { titleCase } from '../../../services/formatting';

import { GlobalSearchModel, ProviderSearchResults } from '../models/global-search';

import { getIcon, getTitle, GlobalSearchResult } from './GlobalSearchResult';

import { DocumentationApi } from '../../documentation/documentation-api';
import { KNOWN_ZOE_DOCS_PATH_MAP } from '../../documentation/views/DocumentationScreen';
import { SearchResult } from '../search-api';
import styles from './GlobalSearchResults.module.css';
import { GlobalSearchViewDelegate } from './GlobalSearchView';

const DOCUMENTATION_KEY = 'documentation';

export const EXTERNAL_TAB_KEYS = ['not-' + DOCUMENTATION_KEY, DOCUMENTATION_KEY];

export function GlobalSearchResults({ model, delegate }: { model: GlobalSearchModel; delegate: GlobalSearchViewDelegate }) {
  const KNOWN_DOCS_MAP = useRef<{ [key: string]: string }>(KNOWN_ZOE_DOCS_PATH_MAP);
  useEffect(() => {
    const getKnownDocsUrls = async () => {
      try {
        const api = new DocumentationApi();
        KNOWN_DOCS_MAP.current = {
          ...(await api.getAllDocumentationLinks())?.reduce(
            (acc, doc) => {
              acc[doc.id] = doc.id; //all docs have the path as the id...
              return acc;
            },
            {} as { [key: string]: string }
          ),
          ...KNOWN_ZOE_DOCS_PATH_MAP, //...except for these ones
        } as { [key: string]: string };
      } catch (error) {
        console.error('Failed to fetch documentation links:', error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getKnownDocsUrls();
  }, []);
  const navigate = useNavigate();

  const { status, searchResults, inputValue, selectedTab: selectedTab, selectedExternalModalTab } = model;

  const handleSearchButton = useCallback(() => {
    void delegate.onGlobalSearch();
  }, [delegate]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') {
        return;
      }

      void delegate.onGlobalSearch();
    },
    [delegate]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      delegate.onSearchTermChange(e.target.value);
    },
    [delegate]
  );

  const handleClickItem = useCallback(
    (key: string) => {
      delegate.onSearchTermChange('');
      delegate.clearSearchResults();

      const url = key.substring(key.indexOf('|') + 1);
      if (url !== '') {
        const external = url.startsWith('http');
        if (!external) {
          navigate(url);
          return;
        }
        window.location.href = url;
      } else {
        console.error('Url not found for key:', key);
      }
    },
    [navigate, delegate]
  );

  const handleTabChange = useCallback(
    (tabId: string) => {
      delegate.onSelectTab(tabId);
    },
    [delegate]
  );

  const handleExternalTabChange = useCallback(
    (tabId: string) => {
      delegate.onSelectExternalTab(tabId);
    },
    [delegate]
  );

  const areThereResults = Object.keys(searchResults).length > 0;
  const areThereDocsResults = searchResults && searchResults[DOCUMENTATION_KEY] && Object.keys(searchResults[DOCUMENTATION_KEY]).length > 0;

  const documentationSearchResults = searchResults?.documentation || {};

  const notDocsSearchResultsSum = Object.values(searchResults || {})
    .filter((entry) => entry.label !== DOCUMENTATION_KEY)
    .map((entry) => entry.searchResults.length)
    .reduce((sum, length) => sum + length, 0);

  const isLandingPageResultsLoading = Object.entries(searchResults || {})
    .filter(([key]) => key !== DOCUMENTATION_KEY)
    .some(([, result]) => result.status === 'loading');

  let searchResultEntries: ([string, ProviderSearchResults] | string)[] = areThereResults
    ? Object.entries(searchResults)
    : window.CONFIG.searchProviders;
  searchResultEntries = searchResultEntries.filter((entry): entry is [string, ProviderSearchResults] => {
    if (typeof entry === 'string') {
      return !entry.includes(DOCUMENTATION_KEY);
    }
    const [key] = entry;
    return !key.includes(DOCUMENTATION_KEY);
  });

  const tabPanes: TabsProps['items'] = searchResultEntries.map((entry) => {
    let key: string;
    let value: ProviderSearchResults | undefined;
    if (typeof entry === 'string') {
      key = entry;
    } else {
      [key, value] = entry;
    }

    const searchResultsForType: (SearchResult | undefined)[] = value?.searchResults || [];

    return {
      key,
      label: (
        <span>
          {titleCase(getTitle(key))}
          {value?.status === 'loaded' && (
            <span className={searchResultsForType.length > 0 ? styles.boldLabel : ''}>{' (' + searchResultsForType.length + ')'}</span>
          )}
        </span>
      ),
      icon: value?.status === 'loading' ? <LoadingOutlined /> : getIcon(key),
      children: value && (
        <GlobalSearchResult
          key={key}
          query={inputValue}
          result={value}
          onClickItem={handleClickItem}
          knownDocsMap={KNOWN_DOCS_MAP.current}
        />
      ),
    };
  });

  const externalTabPanes = [
    {
      key: EXTERNAL_TAB_KEYS[0],
      label: (
        <span>
          {window.CONFIG.branding.longName}
          {areThereResults && (
            <span className={notDocsSearchResultsSum > 0 ? styles.boldLabel : ''}>{' (' + notDocsSearchResultsSum + ')'}</span>
          )}
        </span>
      ),

      icon: isLandingPageResultsLoading ? <LoadingOutlined /> : <SearchOutlined />,
      children: (
        <Tabs
          tabPosition={'left'}
          className={'w-full'}
          type={'line'}
          items={tabPanes}
          onChange={handleTabChange}
          activeKey={selectedTab}
          centered
        />
      ),
    },
    {
      key: DOCUMENTATION_KEY,
      label: (
        <span>
          {titleCase(getTitle(DOCUMENTATION_KEY))}
          {documentationSearchResults.status === 'loaded' && (
            <span className={documentationSearchResults.searchResults.length > 0 ? styles.boldLabel : ''}>
              {' (' + documentationSearchResults.searchResults.length + ')'}
            </span>
          )}
        </span>
      ),
      icon: documentationSearchResults.status === 'loading' ? <LoadingOutlined /> : <SearchOutlined />,
      children: areThereDocsResults ? (
        <GlobalSearchResult
          key={DOCUMENTATION_KEY}
          query={inputValue}
          result={searchResults[DOCUMENTATION_KEY]}
          onClickItem={handleClickItem}
          knownDocsMap={KNOWN_DOCS_MAP.current}
        />
      ) : (
        <></>
      ),
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Space direction={'vertical'} className={'w-full'}>
        <div className={styles.inputContainer}>
          <label>Search:</label>
          &nbsp;&nbsp;&nbsp;
          <Input
            className={styles.inputTextbox}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={'Search...'}
            disabled={status === 'loading'}
            addonAfter={<SearchOutlined onClick={handleSearchButton} />}
          />
        </div>
        <div className={`${styles.cardContainer} w-full`}>
          <Tabs
            tabPosition={'top'}
            className={`${styles.externalTabs} w-full`}
            type={'line'}
            items={externalTabPanes}
            onChange={handleExternalTabChange}
            activeKey={selectedExternalModalTab}
          />
        </div>
      </Space>
    </div>
  );
}
