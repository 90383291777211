import { ConfigProvider, Layout } from 'antd';
import { Suspense, useEffect, useState } from 'react';
import { Outlet, Route, HashRouter as Router, Routes } from 'react-router-dom';

import { SignedInUser } from './services/auth';

import { SideNavRouteProps, sideNavRoutes } from './_shared/AppRoutes';

import { AppThemeContext, useThemeContextValue } from './hooks/use-theme';
import { useDashboards } from './pages/dashboards/dashboard-hooks';
import { useDocumentation } from './pages/documentation/documentation-hooks';
import { InstanceListModel } from './pages/instances/models/instance-list';
import { InstanceListDelegate } from './pages/instances/views/InstanceList';
import { useJobs } from './pages/jobs/jobs-hooks';
import { useServices } from './pages/services/services-hooks';
import { UsageWarningScreen } from './pages/usage/views/UsageWarningScreen';
import { FLAG_ENABLE_WB_INSIDE_ZOE } from './pages/user-info/models/user-info';
import { useUserInfo } from './pages/user-info/user-info-hook';

import { useNotifications } from './side-nav/controls/notifications/notifications-hooks';
import { NotificationRelatedItemDispatcher } from './side-nav/controls/notifications/views/NotificationRelatedItemDispatcher';

import { AppContentWbInsideZoe } from './AppContentWbInsideZoe';

import SideNav, { SideNavProps } from './side-nav/SideNav';

import getAntDTheme from './utils/antd-theme';

import { GlobalSearchController } from './pages/global-search/global-search-controller';
import { GlobalSearchModel } from './pages/global-search/models/global-search';
import './theme.common.less';
import './theme.dark.less';
import './theme.light.less';

const { Content } = Layout;

export function WorkspaceAuthenticatedApp({ user }: { user: SignedInUser }) {
  const themeContextValue = useThemeContextValue();

  const [sidebarMenuCollapsed, setSidebarMenuCollapsed] = useState(false);

  const { dashboardModel, dashboardDelegate } = useDashboards();
  const { servicesModel, servicesDelegate } = useServices();
  const { jobsModel, jobsDelegate } = useJobs();
  const { documentationModel } = useDocumentation();
  const { notificationsModel, notificationsDelegate } = useNotifications();
  const { userInfoModel, userInfoDelegate } = useUserInfo();

  useEffect(() => {
    void (async () => {
      try {
        await userInfoDelegate?.onAppStartedWithUser();
        userInfoDelegate?.onAppStartedInsideWorkspaces();
      } catch (e) {
        console.error('[wow] failed to initialise...', e);
      }
    })();
  }, [userInfoDelegate]);

  const wbInsideZoe = userInfoModel.featureFlags.includes(FLAG_ENABLE_WB_INSIDE_ZOE);

  const [instancesModel, setInstancesModel] = useState<InstanceListModel | null>(null);
  const [instancesDelegate, setInstancesDelegate] = useState<InstanceListDelegate | null>(null);
  const [sideNavRouteParams, setSideNavRouteParams] = useState<SideNavRouteProps | null>(null);
  const [globalSearchModel, setGlobalSearchModel] = useState<GlobalSearchModel | null>(null);
  const [globalSearchDelegate, setGlobalSearchDeglobalSearchDelegate] = useState<GlobalSearchController | null>(null);

  const handleSetInstancesModel = (model: InstanceListModel) => {
    setInstancesModel(model);
  };

  const handleSetInstancesDelegate = (delegate: InstanceListDelegate) => {
    setInstancesDelegate(delegate);
  };

  useEffect(() => {
    if (
      !dashboardModel ||
      !dashboardDelegate ||
      !jobsModel ||
      !jobsDelegate ||
      !servicesModel ||
      !servicesDelegate ||
      !notificationsModel ||
      !notificationsDelegate ||
      !userInfoModel ||
      !documentationModel ||
      !globalSearchModel ||
      !globalSearchDelegate
    ) {
      return;
    }

    const updatedParams: SideNavRouteProps = {
      dashboardModel,
      dashboardDelegate,
      jobsModel,
      jobsDelegate,
      servicesModel,
      servicesDelegate,
      notificationsModel,
      notificationsDelegate,
      userInfoModel,
      documentationModel,
      globalSearchModel,
      globalSearchDelegate,
    };

    if (instancesModel) {
      updatedParams.instancesModel = instancesModel;
    }

    if (instancesDelegate) {
      updatedParams.instancesDelegate = instancesDelegate;
    }

    setSideNavRouteParams(updatedParams);
  }, [
    dashboardModel,
    dashboardDelegate,
    jobsModel,
    jobsDelegate,
    servicesModel,
    servicesDelegate,
    notificationsModel,
    notificationsDelegate,
    userInfoModel,
    documentationModel,
    instancesModel,
    instancesDelegate,
  ]);

  if (!dashboardDelegate || !jobsDelegate || !servicesDelegate || !userInfoDelegate || !sideNavRouteParams) {
    return null;
  }

  const loadingElement = <div>Loading&#8230;</div>;
  if (userInfoModel.loading) {
    return loadingElement;
  }

  const navParams: SideNavProps & SideNavRouteProps = {
    ...sideNavRouteParams,
    userInfoDelegate,
    signOut: () => {},
    user,
  };

  return (
    <AppThemeContext.Provider value={themeContextValue}>
      <ConfigProvider theme={getAntDTheme(themeContextValue.theme)}>
        <UsageWarningScreen />
        <Router>
          <Suspense fallback={loadingElement}>
            <Routes>
              <Route
                path="/"
                element={
                  <div className={`app-background non-visible-sidebar`}>
                    <div className="pwa-header">Zoe Data Explorer</div>
                    <NotificationRelatedItemDispatcher notificationsDelegate={notificationsDelegate} />
                    <Layout>
                      {wbInsideZoe ? (
                        <AppContentWbInsideZoe
                          navParams={{ ...navParams }}
                          notificationsModel={notificationsModel}
                          notificationsDelegate={notificationsDelegate!}
                          setInstancesModel={handleSetInstancesModel}
                          setInstancesDelegate={handleSetInstancesDelegate}
                        />
                      ) : (
                        <>
                          <SideNav {...navParams} sidebarCollapsed={sidebarMenuCollapsed} onSidebarCollapsed={setSidebarMenuCollapsed} />
                          <Content>
                            <Outlet />
                          </Content>
                        </>
                      )}
                    </Layout>
                  </div>
                }
              >
                {sideNavRoutes(sideNavRouteParams)}
              </Route>
            </Routes>
          </Suspense>
        </Router>
      </ConfigProvider>
    </AppThemeContext.Provider>
  );
}
