import { Typography } from 'antd';
import React from 'react';

import styles from './PageHeader.module.css';

const { Title } = Typography;

export function PageHeader({
  headerText,
  containerClassName,
  rightButtonsContainer,
}: {
  headerText: React.ReactNode;
  rightButtonsContainer?: React.ReactNode | undefined;
  containerClassName?: string | undefined;
}) {
  return (
    <div className={`${styles.pageHeader} ${containerClassName || ''}`}>
      <Title level={1}>{headerText}</Title>
      {rightButtonsContainer && <div className={styles.pageHeaderRight}>{rightButtonsContainer}</div>}
    </div>
  );
}

PageHeader.defaultProps = {
  rightButtonsContainer: undefined,
};

export default PageHeader;
