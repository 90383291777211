import { ErrorNotification } from '../../../../services/error-notification';

import { NotificationsApi } from '../notifications-api';
import { NotificationItem } from './notification';

export interface NotificationsListModel {
  loading: boolean;
  notifications: NotificationItem[];
}

export function initialNotificationsListModel(): NotificationsListModel {
  return {
    loading: true,
    notifications: [],
  };
}

export function updateNotificationList(model: NotificationsListModel, newNotifications: NotificationItem[]): NotificationsListModel {
  // the update comes in the form of a delta.
  const notifications = [...model.notifications, ...newNotifications].map((notification) => ({
    ...notification,
    updatedAt: new Date(notification.updatedAt),
  }));
  const sortedNotifications = notifications.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime());
  return { ...model, loading: false, notifications: sortedNotifications };
}

export async function createSetNotificationDismissed(
  model: NotificationsListModel,
  api: NotificationsApi,
  handleError: (error: ErrorNotification | undefined) => void,
  notificationId?: number
): Promise<NotificationsListModel> {
  const response = await api.setNotificationDismissed(notificationId);
  if (!response) {
    handleError({
      message: 'Failed to set notification status',
      deduplicationKey: 'notification-update-fail',
    });
    return model;
  }

  return notificationId
    ? {
        ...model,
        notifications: model.notifications.filter(({ id }) => id !== notificationId),
      }
    : {
        ...model,
        notifications: [],
      };
}

export async function createSetNotificationSeenUnsync(
  model: NotificationsListModel,
  notificationId: number
): Promise<NotificationsListModel> {
  const notification = model.notifications.find((n) => n.id === notificationId);
  if (!notification || notification.seenUnsync) {
    return model;
  }

  notification.seenUnsync = true;

  return { ...model, notifications: model.notifications.map((n) => (n.id === notificationId ? notification : n)) };
}

export async function createSetNotificationsSeen(
  model: NotificationsListModel,
  api: NotificationsApi,
  handleError: (error: ErrorNotification | undefined) => void
): Promise<NotificationsListModel> {
  const seenNotificationsIds = model.notifications.filter((n) => n.seenUnsync).map((n) => n.id);
  if (seenNotificationsIds.length === 0) {
    return model;
  }

  const response = await api.setNotificationsSeen(seenNotificationsIds);
  if (!response) {
    handleError({
      message: 'Failed to set notification as seen',
      deduplicationKey: 'notification-update-fail',
    });
    return model;
  }

  return {
    ...model,
    notifications: model.notifications.map((n) =>
      seenNotificationsIds.includes(n.id) ? { ...n, isSeen: true, seenUnsync: false } : { ...n }
    ),
  };
}
