import React from 'react';
import { ExtraProps } from 'react-markdown';
import { DocumentationMdProps, DocumentationMdComponent } from '../MarkdownDocumentation';

export function HeadingComponent({
  node,
  mdPage,
  docEndpoint,
  children,
  ...props
}: React.ClassAttributes<HTMLHeadingElement> &
  React.HTMLAttributes<HTMLHeadingElement> &
  ExtraProps &
  DocumentationMdProps): DocumentationMdComponent {
  const text = React.Children.toArray(children).reduce(flatten, '');
  const slug = text
    .toLowerCase()
    .replace(/[^a-zA-Z0-9_\-\s]/g, '')
    .replace(/\W/g, '-');
  return React.createElement(node?.tagName ?? 'h1', { id: slug, ...props }, children);
}

function flatten(text: string, child: Exclude<React.ReactNode, boolean | null | undefined>): string {
  return typeof child === 'string'
    ? text + child
    : React.Children.toArray((child as React.ReactElement).props.children).reduce(flatten, text);
}
