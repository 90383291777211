import { checkAxiosResponse, handleAxiosError } from '@mst-fe/shared/dist/errors/axios-errors';
import { LinkedError } from '@mst-fe/shared/dist/errors/linked-error';
import axios from 'axios';

import { LONG_POLLING_INTERVAL } from '../../constants';
import { axiosOptions, initEventSourceEndpoint } from '../../services/requests';
import { WbInstanceData, WbInstanceStatus } from '../instances/workbench-api';
import { UserData } from '../users/users-api';

export type WbServiceType = 'streamlit' | 'voila' | 'web-python' | 'browser-app';

export type WbServiceStatus = 'PROVISIONING' | 'STOPPING' | 'RUNNING' | 'FAILED' | 'STOPPED' | 'REMOVING' | 'UNINSTALLING';

export type UserServerStatus = 'RUNNING' | 'STOPPED' | 'PROVISIONING' | 'STOPPING' | 'FAILED';

export interface WbServiceData {
  id: string;
  serverId: string;
  serverName: string;
  shortId: string;
  wbName: string;
  name: string;
  type: WbServiceType;
  status: WbServiceStatus;
  instanceStatus?: WbInstanceStatus;
  url: string;
  ownedByUser: boolean;
  isDefaultServer: boolean;
  isPublishedByYou: boolean;
  isSharedToOthers: boolean;
  isInstalledFromStore: boolean;
  publishedModuleId?: string;
  permissions: SharePermissions;
}

export interface Server {
  id: string;
  name: string;
  mem: number;
  cpu: number;
  status: UserServerStatus;
  buildLogsPath: string;
}

export type SharePermissions = { type: 'organization' } | { type: 'users'; userSubjects: string[] };

export class ServicesApi {
  async getInstances() {
    return await checkAxiosResponse(axios.get<WbInstanceData[]>('/api/workbench/instances/user?extendedInfo=true', await axiosOptions()));
  }

  async initUserServicesInterval() {
    return await initEventSourceEndpoint(`/api/zoe/dashboard/user-services/sse?interval=${LONG_POLLING_INTERVAL}`);
  }

  async getServers(wbShortId: string): Promise<Server[] | undefined> {
    return await checkAxiosResponse(axios.get<Server[]>(`/api/madcat/${wbShortId}/servers/`, await axiosOptions()));
  }

  async getAuthorizedUsers(wbShortId: string): Promise<UserData[] | undefined> {
    return await checkAxiosResponse(axios.get<UserData[]>(`/api/madcat/${wbShortId}/users`, await axiosOptions()));
  }

  async deployService(wbShortId: string, serviceId: string): Promise<{ ok: boolean; error: string } | undefined> {
    const responseOrError = await handleAxiosError(
      axios.post<{ ok: boolean; error: string }>(`/api/madcat/${wbShortId}/services/${serviceId}/deploy`, {}, await axiosOptions())
    );
    if (responseOrError instanceof LinkedError) {
      if (responseOrError.cause.responseStatus === 400) {
        return responseOrError.cause.responseData as { ok: boolean; error: string };
      }

      return undefined;
    }

    return responseOrError;
  }

  async stopService(wbShortId: string, serviceId: string): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(
      axios.post<{ ok: boolean }>(`/api/madcat/${wbShortId}/services/${serviceId}/stop`, {}, await axiosOptions())
    );
  }

  async deleteService(wbShortId: string, serviceId: string): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(axios.delete<{ ok: boolean }>(`/api/madcat/${wbShortId}/services/${serviceId}`, await axiosOptions()));
  }

  async uninstallService(serviceId: string): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(
      axios.post<{ ok: boolean }>(`/api/madcat/uninstall-browser-app/service/${serviceId}`, {}, await axiosOptions())
    );
  }

  async shareService(wbShortId: string, serviceId: string, permissions: SharePermissions): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(
      axios.post<{ ok: boolean }>(`/api/madcat/${wbShortId}/services/${serviceId}/permissions`, permissions, await axiosOptions()),
      'error upsert permissions'
    );
  }
}
