import { NavigateFunction } from 'react-router-dom';

import { getInstanceUrl } from '../../../../pages/shared/instance-mapping';
import { openUrl } from '../../../../services/windows';

import { NotificationItem } from '../models/notification';

export function handleReferencedEntityItem(item: NotificationItem, { navigate }: { navigate: NavigateFunction }) {
  const { referencedEntity, referencedAction } = item;
  if (!referencedEntity || !referencedAction) {
    return;
  }

  switch (referencedAction) {
    case 'open-workbench':
      openUrl(getInstanceUrl(referencedEntity));
      break;
    case 'navigate-url':
      navigate(referencedEntity);
      break;
  }
}
