/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useMemo, useState } from 'react';

import { Button, Divider, Dropdown, FormInstance, Space } from 'antd';
import { ItemType } from 'antd/lib/menu/interface';

import { DownloadOutlined } from '@ant-design/icons';

import defer from 'lodash/defer';

import axios from 'axios';

import { checkAxiosResponse } from '@mst-fe/shared/dist/errors/axios-errors';

import { showErrorNotification, showNotification } from '../../../../../../_shared/views/Notifications';
import { getAccessTokenFromCookie } from '../../../../../../services/auth';

import { ROOT_SERVER_URL } from '../../../../../utils/midas-constants';

import { GetUrlForQuery } from '../../outputs/hooks/use-data-requester';

import classes from '../shared.module.css';

type DownloadButtonParams = {
  formInstance?: FormInstance | null;
  downloadQuery?: GetUrlForQuery;
  customProducts?: string[];
  allowedFormats?: string[];
  isFormValid?: boolean;
};

const getDownloadData = (key: string) => {
  // extra guard for the possibility of '_' in product name
  const keyData = key.split('_');
  const downloadType = keyData.slice(-1)[0];
  const productData = keyData.slice(0, -1).join('_');
  return [productData, downloadType];
};

const DEFAULT_PROPS = {
  allowedFormats: ['csv', 'json'],
};

export default function DownloadButton({
  formInstance = null,
  downloadQuery,
  allowedFormats = DEFAULT_PROPS.allowedFormats,
  customProducts,
  isFormValid,
}: DownloadButtonParams) {
  const [products, setProducts] = useState<string[]>(customProducts || formInstance?.getFieldValue('products') || []);

  const handleVisibilityChanged = useCallback(
    (visible: boolean) => {
      if (!visible) {
        return;
      }

      setProducts(customProducts || formInstance?.getFieldValue('products'));
    },
    [customProducts, formInstance]
  );

  const handleItemClicked = useCallback(
    ({ key }: { key: string }) => {
      const [data, downloadType] = getDownloadData(key);

      showNotification({
        type: 'info',
        message: `Generating ${downloadType.toUpperCase()} file...`,
        description: `Now generating a file containing "${data}" data...`,
        duration: 2,
      });
      defer(async () => {
        try {
          const url = downloadQuery?.(formInstance?.getFieldsValue(), data, downloadType);
          if (!url) {
            throw new Error('Could not download since an empty url was provided');
          }
          const definedUrl: string = url as string;
          const token = getAccessTokenFromCookie();
          const content = await checkAxiosResponse(
            axios.get(definedUrl, {
              headers: { Authorization: `Bearer ${token}` },
              validateStatus: null,
            }),
            'Error while downloading data'
          );
          if (!content) {
            throw new Error('Error while downloading data');
          }
          window.location.href = ROOT_SERVER_URL + content;
        } catch (err: unknown) {
          console.error('[DownloadButton] failed to run...', err);

          showErrorNotification({
            message: 'Unable to generate a download URL for the specified value...',
            description: (err as Error).message,
          });
        }
      });
    },
    [downloadQuery, formInstance]
  );

  const menuItems = useMemo<ItemType[]>(() => {
    if (!products.length) {
      return [
        {
          key: '',
          label: 'No items available; please ensure the form above is completed...',
          disabled: true,
        },
      ];
    }
    return products.reduce((result: ItemType[], product: string) => {
      if (allowedFormats.includes('csv')) {
        result.push({
          key: `${product}_csv`,
          label: `Download '${product}' CSV...`,
        });
      }
      if (allowedFormats.includes('json')) {
        result.push({
          key: `${product}_json`,
          label: `Download '${product}' JSON...`,
        });
      }
      return result;
    }, []);
  }, [products, allowedFormats]);

  const dropdownMenu = { onClick: handleItemClicked, items: menuItems };

  return (
    <Space className={classes.downloadButtonContainer}>
      <Divider type="vertical" />
      <Dropdown disabled={!downloadQuery || !isFormValid} menu={dropdownMenu} onOpenChange={handleVisibilityChanged} trigger={['click']}>
        <Button icon={<DownloadOutlined />} htmlType="button">
          Download Data
        </Button>
      </Dropdown>
    </Space>
  );
}
