import { NotificationsApi } from '../notifications-api';

export interface NotificationItem {
  id: number;
  userSubject: string;
  referencedEntity?: string | undefined;
  awsNotificationId?: string | undefined;
  criticality: number;
  isDismissed?: number;
  dismissedAt?: Date | undefined;
  title: string;
  description: string | undefined;
  jsonData?: string | undefined;
  createdAt: Date;
  updatedAt: Date;
  isSeen: boolean;
  seenUnsync?: boolean;
  referencedAction?: string;
}

export async function loadNotifications(api: NotificationsApi, getExisting: () => NotificationItem[]) {
  const existingItems = getExisting();
  const maxId = existingItems.length ? Math.max(...existingItems.map(({ id }) => id)) : 0;

  const notifications = await api.getOutstandingNotificationsAfterId(maxId);
  if (!notifications) {
    return undefined;
  }

  return notifications;
}
