import React, { useRef, useCallback } from 'react';

import { HistoryContext } from './history-context';
import { To } from 'react-router-dom';

type HistoryContextProviderWrapperParams = {
  children: JSX.Element;
};

export default function HistoryContextProviderWrapper({ children }: HistoryContextProviderWrapperParams) {
  const eventHandler = useRef<(value: To) => void>();

  // for some reason, using `history.push` here does not work with the React router.
  // so we have out own component that the context binds together.
  // not a nice solution, but it works, and we really don't have time to fix this.
  const goHistory = useCallback(
    (value: To) => {
      if (eventHandler.current) {
        eventHandler.current(value);
      } else {
        console.warn('[HistoryContextProviderWrapper] tried to redirect history but no registered handler!');
      }
    },
    [eventHandler]
  );

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <HistoryContext.Provider value={{ go: goHistory, eventHandler }}>{children}</HistoryContext.Provider>;
}
