import { VERY_LONG_POLLING_INTERVAL } from '../../constants';
import { handleError } from '../../services/error-notification';
import { PeriodicRequest } from '../../services/periodic-request';
import {
  DataLakeMonitorStore,
  initialDataLakeMonitorStore,
  setDataLakeStoreLoading,
  setDataLakeStoreRunning,
  updateDataLakeDaysToGoBack,
  updateDataLakeMonitorStore,
  updateDataLakeSelectedTables,
} from './models/data-lake-monitor-store';
import { DataLakeStatus, MonitoringApi } from './monitoring-api';
import { DataLakeMonitorViewDelegate } from './views/DataLakeStatusMonitorView';

export class DataLakeMonitorController implements DataLakeMonitorViewDelegate {
  private api = new MonitoringApi();

  private model = initialDataLakeMonitorStore();

  private getModel = (): DataLakeMonitorStore => this.model;

  private servicesPeriodicRequest: PeriodicRequest<DataLakeStatus | undefined>;

  private disposeCallbacks: (() => void)[] = [];

  constructor(private updateViewState: (_: DataLakeMonitorStore) => void) {
    this.servicesPeriodicRequest = new PeriodicRequest({
      interval: VERY_LONG_POLLING_INTERVAL,
      neverCheckEquality: true,

      onPeriodicRequest: async (): Promise<DataLakeStatus | undefined> => {
        this.update(setDataLakeStoreLoading(this.model));
        return await this.api.getDataLakeStatus(this.model.daysToGoBack);
      },

      onPeriodicRequestResult: (result: DataLakeStatus) => {
        if (result !== undefined) {
          this.update(updateDataLakeMonitorStore(this.model, result));
        }
      },
    });

    this.disposeCallbacks.push(this.servicesPeriodicRequest.start(true));
  }

  async reloadDataLakeStatus(): Promise<void> {
    this.update(setDataLakeStoreRunning(this.model));

    const res = await this.api.refreshDataLakeStatus();

    if (!res?.ok) {
      handleError({ message: res?.error ?? 'Failed to trigger a force refresh.' });
    }
  }

  async runRefresh() {
    this.update(setDataLakeStoreLoading(this.model));

    const result = await this.api.getDataLakeStatus(this.model.daysToGoBack);
    if (!result) {
      return;
    }

    this.update(updateDataLakeMonitorStore(this.model, result));
  }

  selectTables(tables: string[]) {
    this.update(updateDataLakeSelectedTables(this.model, tables));
  }

  async changeDaysToGoBack(daysToGoBack: number | null | undefined) {
    this.update(updateDataLakeDaysToGoBack(this.model, daysToGoBack));
    await this.runRefresh();
  }

  private update = (model: DataLakeMonitorStore) => {
    this.model = model;
    this.updateViewState(model);
  };

  dispose() {
    for (const cb of this.disposeCallbacks) {
      cb();
    }
    this.disposeCallbacks = [];
  }
}
