import { PaddedContainer } from '../../../_shared/PaddedContainer';
import PageHeader from '../../../_shared/PageHeader';
import { GlobalSearchController } from '../global-search-controller';
import { GlobalSearchModel } from '../models/global-search';
import { GlobalSearchResults } from './GlobalSearchResults';

export default function GlobalSearch({ model, delegate }: { model: GlobalSearchModel; delegate: GlobalSearchController }) {
  return (
    (delegate && (
      <PaddedContainer>
        <PageHeader headerText={<div>Global Search</div>} />
        <GlobalSearchResults delegate={delegate} model={model} />
      </PaddedContainer>
    )) ??
    null
  );
}
