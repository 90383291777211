import React from 'react';

import { BellIcon as BellIconOutlined, MoonIcon, SunIcon } from '@heroicons/react/24/outline';
import { BellIcon } from '@heroicons/react/24/solid';
import styles from '../side-nav/SideNav.module.css';

export function MoonIconComponent() {
  return (
    <span>
      <MoonIcon width={16} height={16} />
    </span>
  );
}

export function SunIconComponent() {
  return (
    <span>
      <SunIcon width={18} height={18} />
    </span>
  );
}

export function BellIconComponent() {
  return (
    <span>
      <BellIcon width={18} height={18} />
    </span>
  );
}

export function EmptyBellIconComponent() {
  return (
    <span>
      <BellIconOutlined width={18} height={18} />
    </span>
  );
}

export type ItemWithIconProps = {
  Icon: React.FC;
  value: string;
};

export function ItemWithIcon({ value, Icon }: ItemWithIconProps) {
  return (
    <div className={styles.menuItemWithIcon}>
      <Icon />
      <span>{value}</span>
    </div>
  );
}
