import { NotificationItem } from '../models/notification';
import { NotificationsListModel } from '../models/notifications-list';

import { Button, List } from 'antd';
import { sanitize } from 'dompurify';

import { CloseCircleOutlined } from '@ant-design/icons';

import tinydate from 'tinydate';

import { BellIconComponent, EmptyBellIconComponent } from '../../../../_shared/IconControls';

import { InView } from 'react-intersection-observer';
import styles from './NotificationsListControl.module.css';

const stamp = tinydate('{HH}:{mm}:{ss} {DD}-{MMM}-{YY}', { MMM: (d) => d.toLocaleString('default', { month: 'short' }) });

export interface NotificationListControlDelegate {
  onSetNotificationDismissed(notificationId?: number): Promise<void>;

  onNotificationClicked(notificationId: number): Promise<void>;

  onNotificationInvoked(notificationHandler: (notification: NotificationItem) => Promise<void>): void;

  onSetNotificationSeenUnsync(notificationId: number): Promise<void>;

  onSetNotificationsSeen(): Promise<void>;
}

export function NotificationsListControl({
  notificationsModel,
  notificationsDelegate,
}: {
  notificationsModel: NotificationsListModel;
  notificationsDelegate: NotificationListControlDelegate | undefined;
}) {
  const hasNotifications = notificationsModel.notifications?.length > 0;

  const renderItem = (item: NotificationItem) => {
    const sanitizedDescription = sanitize(item.description ?? '');

    return (
      <InView threshold={0.8} trackVisibility delay={200}>
        {({ inView, ref }) => {
          if (inView && !item.isSeen && !item.seenUnsync) {
            void notificationsDelegate?.onSetNotificationSeenUnsync(item.id);
          }

          return (
            <List.Item
              key={item.id}
              role={'button'}
              className={!item.referencedEntity ? styles.notificationItemDisabled : ''}
              onClick={
                item.referencedEntity
                  ? (event) => {
                      event.stopPropagation();
                      event.preventDefault();

                      void notificationsDelegate?.onNotificationClicked?.(item.id);
                    }
                  : undefined
              }
              actions={[
                <Button
                  icon={<CloseCircleOutlined className={styles.clearIcon} />}
                  key={`button_${item.id}`}
                  type={'text'}
                  onClick={async (event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    await notificationsDelegate?.onSetNotificationDismissed(item.id);
                  }}
                />,
              ]}
              ref={ref}
            >
              <List.Item.Meta
                avatar={item.isSeen ? <EmptyBellIconComponent /> : <BellIconComponent />}
                title={item.title}
                description={
                  <>
                    <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }}></div>
                    <div className={styles.notificationDate}>{stamp(new Date(item.createdAt?.toString()))}</div>
                  </>
                }
              />
            </List.Item>
          );
        }}
      </InView>
    );
  };

  return (
    <div className={styles.notificationsMenu}>
      {!hasNotifications && <div className={styles.noNotifications}>No Notifications</div>}
      {hasNotifications && (
        <>
          <div className={styles.notificationsHeader}>
            <Button
              className={styles.clearAllButton}
              onClick={async (event) => {
                event.stopPropagation();
                event.preventDefault();

                await notificationsDelegate?.onSetNotificationDismissed();
              }}
            >
              <CloseCircleOutlined className={styles.clearAllIcon} /> Clear all
            </Button>
          </div>
          <List
            className={styles.popupContainer}
            loading={notificationsModel.loading}
            dataSource={notificationsModel.notifications}
            renderItem={renderItem}
          />
        </>
      )}
    </div>
  );
}
