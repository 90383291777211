import Mark from 'mark.js';
import { useEffect, useMemo, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Button, FloatButton, Form, Space } from 'antd';
import { PaddedContainer } from '../../../_shared/PaddedContainer';
import routes from '../../../utils/routes';
import { DOCUMENTATION_BASE_ENDPOINT, DocumentationApi } from '../documentation-api';
import { DocumentationModel, DocumentationType } from '../models/documentation-list';
import { AComponent } from './markdown-components/AComponent';
import { HeadingComponent } from './markdown-components/HeadingComponent';
import { ImgComponent } from './markdown-components/ImgComponent';

import { CloseOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import styles from './MarkdownDocumentation.module.css';

export interface DocumentationMarkdownPageLocation {
  location: Location;
  filePath: string | undefined;
}

export type DocumentationMdProps = { mdPage: DocumentationMarkdownPageLocation; docEndpoint: string };

export type DocumentationMdComponent = JSX.Element | string | null | undefined;

export const SEARCH_TERM_PREFIX = 'searchTerm';

export const SCROLL_TO_FIRST_MATCH_PREFIX = 'scrollToFirstMatch';

export function isMarkdownFile(filePath?: string) {
  return filePath?.endsWith('.md') || filePath?.endsWith('.mdp');
}

async function loadMarkdownPage(api: DocumentationApi, docType: DocumentationType, filePath: string) {
  if (isMarkdownFile(filePath)) {
    return await api.getMarkdownPage(docType, filePath);
  } else {
    return 'File type not supported.';
  }
}

const MAX_SEARCH_RESULTS = 100;
const SEARCH_FIELD_NAME = 'Query';

const useScrollToHash = (sectionId: string) => {
  useEffect(() => {
    if (sectionId) {
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
    }
  }, [sectionId]);

  return null;
};

export default function MarkdownDocumentation({ docType, model }: { docType: DocumentationType; model: DocumentationModel }) {
  const navigationEnabled = false;
  const documentationItem = useMemo(() => model.documentation.find(({ id }) => id === docType), [model.documentation, docType]);
  const homePath = useMemo(
    () => (documentationItem ? `${routes.Documentation}/${docType}/${documentationItem.url}` : `${routes.Documentation}/${docType}/`),
    [docType, documentationItem]
  );

  const navigate = useNavigate();
  const location = useLocation();
  const { '*': filePath } = useParams();
  const [mdPage, setMdPage] = useState<DocumentationMarkdownPageLocation>({ location, filePath });

  const docApi = useMemo(() => new DocumentationApi(), []);
  const [markdownContent, setMarkdownContent] = useState<string>('');

  const [form] = Form.useForm();

  const markdownRef = useRef<HTMLDivElement>(null);
  const [sectionId, setSectionId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [scrollToFirstMatch, setScrollToFirstMatch] = useState(false);
  const [matches, setMatches] = useState<NodeListOf<Element> | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useScrollToHash(sectionId);

  const getSectionIdFromUrl = () => {
    const hash = window.location.hash;
    if (!hash.includes(SEARCH_TERM_PREFIX)) {
      return hash.replace(/^#/, '');
    }
    return hash.substring(1, hash.indexOf(SEARCH_TERM_PREFIX) - 1);
  };

  const getSearchTermFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(SEARCH_TERM_PREFIX) ? decodeURIComponent(urlParams?.get(SEARCH_TERM_PREFIX) ?? '') : '';
  };

  const getScrollToFirstMatchFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(SCROLL_TO_FIRST_MATCH_PREFIX) === 'true';
  };

  useEffect(() => {
    setSectionId(getSectionIdFromUrl());
    setSearchTerm(getSearchTermFromUrl());
    setScrollToFirstMatch(getScrollToFirstMatchFromUrl());
  }, [location]);

  useEffect(() => {
    if (!searchTerm || !markdownRef.current) return;
    const instance = new Mark(markdownRef.current);
    instance.unmark({
      done: () => {
        setTimeout(() => {
          instance.mark(searchTerm, {
            separateWordSearch: false,
            className: 'highlight',
            done: () => {
              setTimeout(() => {
                const foundMatches = document.querySelectorAll('.highlight');
                setMatches(foundMatches);
                if (scrollToFirstMatch && foundMatches.length > 0) {
                  setCurrentIndex(0);
                  doScrollToFirstMatch(0, foundMatches);
                }
              }, 200);
            },
          });
        }, 200);
      },
    });

    return () => instance.unmark();
  }, [searchTerm, navigate, scrollToFirstMatch]);

  const doScrollToFirstMatch = (index: number, elements: NodeListOf<Element>) => {
    if (elements.length > 0) {
      elements[index].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleNavigation = (direction: 'prev' | 'next') => {
    if (matches && matches.length > 0) {
      // clear 'highlight-current' class
      matches.forEach((match) => {
        const element = match as HTMLElement;
        element.classList.remove('highlight-current');
        element.classList.add('highlight');
      });

      // next index
      const nextIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;
      const clampedIndex = Math.max(0, Math.min(nextIndex, matches.length - 1));
      setCurrentIndex(clampedIndex);

      // add 'highlight-current' class to new result
      const resultElement = matches[clampedIndex];
      if (resultElement) {
        resultElement.classList.remove('highlight');
        resultElement.classList.add('highlight-current');

        // scroll
        resultElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setMatches(null);
    setCurrentIndex(0);
  };

  const mdProps: DocumentationMdProps = useMemo(() => ({ mdPage, docEndpoint: `${DOCUMENTATION_BASE_ENDPOINT}/${docType}` }), [mdPage]);

  useEffect(() => {
    // No page selected, navigate to the entrypoint
    if (!filePath && documentationItem) {
      navigate(documentationItem.url);
    }
  }, [filePath, documentationItem, navigate]);

  useEffect(() => {
    // Page location changed, load the new content
    if (filePath && documentationItem) {
      loadMarkdownPage(docApi, docType, filePath)
        .then((res) => {
          setMarkdownContent(res ?? 'An error occurred while loading the page.');
          setMdPage({ location, filePath });
        })
        .catch(() => {
          setMarkdownContent('An error occurred while loading the page.');
        });
    }
  }, [filePath, documentationItem]);

  if (!documentationItem) {
    return <div className="markdown-body markdown-body-error">Documentation not available.</div>;
  }

  return (
    <PaddedContainer className={styles.container}>
      <Space direction="vertical" className="w-full" ref={markdownRef}>
        {matches && matches.length > 0 && (
          <div>
            <FloatButton.Group
              shape="circle"
              style={{
                right: 20,
                bottom: '95%',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {navigationEnabled && <span>{searchTerm}</span>}
              {navigationEnabled && (
                <span>
                  {currentIndex + 1}/{matches.length}
                </span>
              )}

              {navigationEnabled && <FloatButton icon={<DownOutlined />} onClick={() => handleNavigation('next')} type="default" />}
              {navigationEnabled && <FloatButton icon={<UpOutlined />} onClick={() => handleNavigation('prev')} type="default" />}
              <Button className={styles.clearHighlightsButton} icon={<CloseOutlined />} onClick={handleClearSearch}>
                Clear highlights
              </Button>
            </FloatButton.Group>
          </div>
        )}
        <Markdown
          className="markdown-body"
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw, rehypeHighlight]}
          components={{
            h1: (props) => HeadingComponent({ ...mdProps, ...props }),
            h2: (props) => HeadingComponent({ ...mdProps, ...props }),
            h3: (props) => HeadingComponent({ ...mdProps, ...props }),
            h4: (props) => HeadingComponent({ ...mdProps, ...props }),
            h5: (props) => HeadingComponent({ ...mdProps, ...props }),
            h6: (props) => HeadingComponent({ ...mdProps, ...props }),
            img: (props) => ImgComponent({ ...mdProps, ...props }),
            a: (props) => AComponent({ ...mdProps, ...props }),
          }}
        >
          {markdownContent}
        </Markdown>
      </Space>
    </PaddedContainer>
  );
}
