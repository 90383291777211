import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AppstoreOutlined, FolderOutlined, JavaScriptOutlined, PythonOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Button, MenuProps } from 'antd';

import { MenuItemGroupType, MenuItemType } from 'antd/lib/menu/interface';

import { ChartBarSquareIcon, CodeBracketSquareIcon, WindowIcon } from '@heroicons/react/24/outline';

import { APPLICATIONS_ITEM_KEY, APPLICATIONS_ITEM_KEY_PREFIX, searchableMenuItemLabelMap } from '../../../side-nav/SideNav';

import { WbService } from '../models/service';
import { ServicesListModel } from '../models/services-list';

import sharedStyles from '../../../side-nav/SideNav.shared.module.css';

import styles from './ServicesNav.module.css';

function getServiceIcon(service: WbService) {
  if (service.logo) {
    return <img src={service.logo} alt="logo" style={{ height: '16px', width: '16px', objectFit: 'contain' }} />;
  }

  switch (service.type) {
    case 'streamlit':
      return <WindowIcon height={16} width={16} title={'A Streamlit application, consisting of a React UI coded in Python.'} />;
    case 'voila':
      return (
        <ChartBarSquareIcon height={16} width={16} title={'A Volià application, consisting of a hosted Jupyter notebook and kernel.'} />
      );
    case 'browser-app':
      return <JavaScriptOutlined height={16} width={16} title={'A Javascript application that runs inside the browser.'} />;
    case 'web-python':
      return <PythonOutlined height={16} width={16} title={'A Python application hosted inside a container.'} />;
    default:
      return <CodeBracketSquareIcon height={16} width={16} title={''} />;
  }
}

function getServiceTitle(service: WbService) {
  switch (service.type) {
    case 'streamlit':
      return `${service.name} is a Streamlit application, consisting of a React UI coded in Python.`;
    case 'voila':
      return `${service.name} is a Volià application, consisting of a hosted Jupyter notebook and kernel.`;
    case 'browser-app':
      return `${service.name} is a Javascript application that runs inside the browser.`;
    case 'web-python':
      return `${service.name} is a Python application hosted inside a container.`;
  }
}

function ServicesNav() {
  const navigate = useNavigate();

  const handleButtonClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      evt.preventDefault();

      navigate('/published-manifests?appType=app');
    },
    [navigate]
  );

  return (
    <div data-testid="side-nav-applications" className="sidebar-nav-item-title-icon-container">
      <div>{searchableMenuItemLabelMap.APPLICATIONS}</div>
      <Link to={'/published-manifests?appType=app'} onClick={handleButtonClick}>
        <Button icon={<ShoppingCartOutlined />} type="text" />
      </Link>
    </div>
  );
}

function ServicesNavItem({ model }: { model: WbService }) {
  const path = `/applications/${model.id}`;

  return (
    <div title={getServiceTitle(model)}>
      <div className={sharedStyles.navLabel}>
        <Link to={path}>{model.name}</Link>
        <div className={`${styles.serviceActivation} sidebar-nav-item-service-status`}>{model.status}</div>
      </div>
    </div>
  );
}

function getServicesNavItems(services: WbService[], urlGroupName = '', loading: boolean) {
  if (!services.length) {
    return [
      {
        label: loading ? 'Loading…' : 'No Apps',
        key: `${APPLICATIONS_ITEM_KEY_PREFIX}${urlGroupName}/empty`,
        className: styles.emptyMenuItem,
        disabled: true,
      },
    ];
  }

  return services.map((service) => {
    return {
      label: <ServicesNavItem model={service} />,
      icon: getServiceIcon(service),
      key: `${APPLICATIONS_ITEM_KEY_PREFIX}${service.id}`,
      title: service.name,
    };
  });
}

function ServicesNavGroup(
  { services, groupName }: { services: WbService[]; groupName: string },
  loading: boolean
): MenuItemGroupType<MenuItemType> {
  return {
    label: (
      <div className={'sidebar-group-item'}>
        <FolderOutlined /> {groupName}
      </div>
    ),
    key: `${APPLICATIONS_ITEM_KEY_PREFIX}${groupName}`,
    type: 'group',
    children: getServicesNavItems(services, groupName, loading),
    className: styles.serviceGroup,
  };
}

export function getServicesNav({ model }: { model: ServicesListModel }): Required<MenuProps>['items'][number] {
  const sharedWithMe = model.services.filter((s) => !s.isInstalledFromStore && !s.ownedByUser);
  const installed = model.services.filter((s) => s.isInstalledFromStore);
  const myApps = model.services.filter((s) => !s.isInstalledFromStore && s.ownedByUser);
  const groups = [
    { groupName: 'My Apps', services: myApps },
    { groupName: 'Installed Apps', services: installed },
    { groupName: 'Shared With Me', services: sharedWithMe },
  ];

  return {
    label: <ServicesNav />,
    key: APPLICATIONS_ITEM_KEY,
    icon: <AppstoreOutlined />,
    children: groups.map((g) => ServicesNavGroup(g, model.loading)),
    popupClassName: 'popup-01',
  };
}
