import { LONG_POLLING_INTERVAL } from '../../constants';
import { handleError } from '../../services/error-notification';
import { PeriodicRequest } from '../../services/periodic-request';
import { ConfigSchemaFormModel } from '../../_shared/models/config-schema-form-model';
import { showNotification } from '../../_shared/views/Notifications';
import { JobExecution, JobListItem, JobsApi } from './jobs-api';
import { deleteJob, loadEditJob, toggleJobEnabled, updateJob } from './models/job-edit';
import {
  asEditJobUpdate,
  asJobsListDetaileUpdate,
  asTriggerJobUpdate,
  closeExecutionLogs,
  initialJobsList,
  interruptJobExecution,
  JobsList,
  loadExecutionLogs,
  loadJobDetail,
  setJobExecutionToInterrupt,
  updateJobExecutions,
  updateJobsListItems,
} from './models/jobs-list';
import { editTriggerJobPayload, initialTriggerJobModel, saveTriggerJob } from './models/trigger-job';
import { JobDetailsDelegate } from './views/JobDetails';
import { JobsMenuDelegate } from './views/JobsMenu';

export class JobsController implements JobDetailsDelegate, JobsMenuDelegate {
  private model = initialJobsList();

  private api: JobsApi;

  private jobsListEventSource: EventSource | undefined = undefined;

  private disposeCallbacks: (() => void)[] = [];

  private executionsPeriodicRequest: PeriodicRequest<JobExecution[] | undefined>;

  private getModel = (): JobsList => this.model;

  private update = (model: JobsList) => {
    this.model = model;
    this.updateViewState(model);
  };

  private updateJobDetail = asJobsListDetaileUpdate(this.getModel, this.update);

  private updateEditJob = asEditJobUpdate(this.getModel, this.update);

  private updateTriggerJob = asTriggerJobUpdate(this.getModel, this.update);

  constructor(private updateViewState: (_: JobsList) => void) {
    this.api = new JobsApi();

    let lastRequestJobId: string | undefined;
    this.executionsPeriodicRequest = new PeriodicRequest({
      interval: LONG_POLLING_INTERVAL,

      onPeriodicRequest: async () => {
        lastRequestJobId = this.model.jobDetail?.jobId;
        if (lastRequestJobId) {
          return await this.api.jobExecutions(lastRequestJobId);
        }
      },

      onPeriodicRequestResult: (value: JobExecution[] | undefined) => {
        if (value && this.model.jobDetail?.jobId === lastRequestJobId) {
          updateJobExecutions(this.model, this.updateJobDetail, value);
        }
      },
    });
  }

  start() {
    if (this.disposeCallbacks.length === 0) {
      this.disposeCallbacks.push(this.executionsPeriodicRequest.start());
    }

    if (!this.jobsListEventSource) {
      void (async () => {
        this.jobsListEventSource = await this.initJobsListEventSource();
      })();
    }
  }

  dispose() {
    this.jobsListEventSource?.close();

    for (const cb of this.disposeCallbacks) {
      cb();
    }
    this.disposeCallbacks = [];
  }

  private async restartInterval() {
    this.jobsListEventSource?.close();

    this.jobsListEventSource = await this.initJobsListEventSource();
  }

  private async initJobsListEventSource() {
    const jobsListEventSource = await this.api.initListInterval();

    jobsListEventSource.addEventListener('message', (event) => {
      const value = typeof event.data === 'string' ? (JSON.parse(event.data) as JobListItem[]) : undefined;
      if (value) {
        this.update(updateJobsListItems(this.model, value));
      }
    });
    return jobsListEventSource;
  }

  async onEditJob(job: JobListItem): Promise<void> {
    handleError(await loadEditJob(this.api, job, () => this.model.editJob, this.updateEditJob));
  }

  async onDeleteJob(job: JobListItem, navigate: (target: string) => void): Promise<void> {
    const result = await deleteJob(this.api, job, this.restartInterval.bind(this));
    if (result?.type !== 'error' && window.location.pathname.startsWith(`/jobs/${job.id}`)) {
      navigate('/published-manifests');
    }

    if (result) {
      showNotification(result);
    }
  }

  async onEnableDisableJob(job: JobListItem): Promise<void> {
    handleError(await toggleJobEnabled(this.api, job, this.restartInterval.bind(this)));
  }

  async onEditJobConfirm(): Promise<void> {
    handleError(await updateJob(this.api, () => this.model.editJob, this.restartInterval.bind(this), this.updateEditJob));
  }

  async onLoadJobDetails(jobId: string): Promise<void> {
    handleError(await loadJobDetail(this.api, this.model, this.updateJobDetail, jobId));
  }

  async onViewExecutionLogs(execution: JobExecution): Promise<void> {
    handleError(await loadExecutionLogs(this.api, this.getModel, this.update, execution.id));
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async onInterruptExecution(execution: JobExecution): Promise<void> {
    this.update(setJobExecutionToInterrupt(this.model, execution));
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async onCancelInterruptExecution(): Promise<void> {
    this.update(setJobExecutionToInterrupt(this.model, undefined));
  }

  async onDoInterruptExecution(): Promise<void> {
    handleError(await interruptJobExecution(this.api, this.getModel, this.update));
  }

  onCancelEditJob(): void {
    this.updateEditJob(undefined);
  }

  onConfigChange(config: ConfigSchemaFormModel['config']): void {
    if (this.model.editJob) {
      this.updateEditJob({ ...this.model.editJob, config });
    }
  }

  onViewLogsClose(): void {
    this.update(closeExecutionLogs(this.model));
  }

  async onViewLogsRefresh(): Promise<void> {
    const id = this.model.jobExecutionLogs?.id;
    if (!id) {
      return;
    }

    handleError(await loadExecutionLogs(this.api, this.getModel, this.update, id));
  }

  onTriggerJob(job: JobListItem): void {
    this.updateTriggerJob(initialTriggerJobModel(job.id));
  }

  onTriggerJobPayload(value: string): void {
    if (this.model.triggerJob) {
      this.updateTriggerJob(editTriggerJobPayload(this.model.triggerJob, value));
    }
  }

  onTriggerJobCancel(): void {
    this.updateTriggerJob(undefined);
  }

  async onTriggerJobConfirm(): Promise<void> {
    if (this.model.triggerJob) {
      handleError(await saveTriggerJob(this.api, () => this.model.triggerJob, this.restartInterval.bind(this), this.updateTriggerJob));
    }
  }
}
