import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import SideNav, { SideNavProps } from './side-nav/SideNav';
import { WorkbenchInstanceFakeContainer } from './pages/instances/views/WorkbenchInstanceFakeContainer';
import { SideNavRouteProps } from './_shared/AppRoutes';
import { NotificationsListModel } from './side-nav/controls/notifications/models/notifications-list';
import { NotificationController } from './side-nav/controls/notifications/notifications-controller';
import { useInstances } from './pages/instances/instances-hooks';

interface AppContentWbInsideZoeProps {
  navParams: SideNavProps & SideNavRouteProps;
  notificationsModel: NotificationsListModel;
  notificationsDelegate: NotificationController;
  setInstancesModel: (model: any) => void;
  setInstancesDelegate: (delegate: any) => void;
}

const { Content } = Layout;

export function AppContentWbInsideZoe({ navParams, setInstancesModel, setInstancesDelegate }: AppContentWbInsideZoeProps) {
  const { instancesModel, instancesDelegate } = useInstances();

  useEffect(() => {
    if (instancesModel) {
      setInstancesModel(instancesModel);
    }
  }, [instancesModel, setInstancesModel]);

  useEffect(() => {
    if (instancesDelegate) {
      setInstancesDelegate(instancesDelegate);
    }
  }, [instancesDelegate, setInstancesDelegate]);

  if (!instancesDelegate) {
    return <></>;
  }

  return (
    <>
      <SideNav {...navParams} instancesModel={instancesModel} instancesDelegate={instancesDelegate} />
      <Content className={'primary-container'}>
        <Outlet />
        <WorkbenchInstanceFakeContainer model={instancesModel} delegate={instancesDelegate} />
      </Content>
    </>
  );
}
