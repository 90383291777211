import React, { useCallback, useContext } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';

import { AppThemeContext } from '../../../hooks/use-theme';

import { GlobalSearchModel } from '../models/global-search';

import { useLocation, useNavigate } from 'react-router-dom';
import { GLOBAL_SEARCH_KEY } from '../../../side-nav/SideNav';
import styles from './GlobalSearchView.module.css';

export interface GlobalSearchViewDelegate {
  onGlobalSearch(): Promise<void>;

  onSearchTermChange(term: string): void;

  clearSearchResults(): void;

  onSelectTab(tabId: string): void;

  onSelectExternalTab(tabId: string): void;
}

export function GlobalSearchInputs({ model, delegate }: { model: GlobalSearchModel; delegate: GlobalSearchViewDelegate }) {
  const location = useLocation();
  const navigate = useNavigate();

  const search = useCallback(() => {
    if (location.pathname !== GLOBAL_SEARCH_KEY) {
      navigate(GLOBAL_SEARCH_KEY);
    }
    void delegate.onGlobalSearch();
  }, [delegate, location.pathname, navigate]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      delegate.onSearchTermChange(e.target.value);
    },
    [delegate]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') {
        return;
      }

      void search();
    },
    [search]
  );

  const { theme } = useContext(AppThemeContext);
  const style = {
    borderColor: theme === 'light' ? '#595959' : 'white', // `1px solid ${theme === 'light' ? '#595959' : 'white'}`, // was rgb(171,173,178)
    backgroundColor: theme === 'light' ? 'white' : 'var(--gray-12)',
  };

  const disabled = model.status === 'loading';
  const globalSearchInputs = (
    <Space.Compact className={`${styles.searchContainer}`}>
      <Input
        maxLength={40}
        className={styles.globalSearch}
        value={model.inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={'Search...'}
        aria-label="Search in the website"
        id="globalSearch"
        style={style}
        disabled={disabled}
      />
      <Button onClick={search} style={style} disabled={disabled} icon={<SearchOutlined />} />
    </Space.Compact>
  );

  return <>{globalSearchInputs}</>;
}
