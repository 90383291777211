/* eslint-disable no-case-declarations */
import { Menu, MenuProps, Tooltip } from 'antd';
/* do not change this - it has to be /es/ */
import { SiderContext } from 'antd/es/layout/Sider';

import { getInstanceUrl } from '../../shared/instance-mapping';

import { ProviderSearchResults } from '../models/global-search';

import {
  AppstoreOutlined,
  CloudServerOutlined,
  DashboardOutlined,
  DesktopOutlined,
  FileMarkdownOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  PlayCircleOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import MenuItem from 'antd/es/menu/MenuItem';
import { useCallback, useState } from 'react';
import { SearchResult, SectionedHighlight } from '../search-api';

import { searchableMenuItemLabelMap } from '../../../side-nav/SideNav';
import {
  API_CLIENT_USER_GUIDE_ZOE_PATH,
  BELLPORT_DOCUMENTATION_ZOE_PATH,
  NORMALIZATION_GUIDE_ZOE_PATH,
} from '../../documentation/views/DocumentationScreen';
import { isMarkdownFile, SEARCH_TERM_PREFIX } from '../../documentation/views/MarkdownDocumentation';
import styles from './GlobalSearchResults.module.css';

type MenuItem = Required<MenuProps>['items'][number];

/* antd being too clever for itself - assumes anything created under a Sider will depend on the Sider's context */
const fakeSiderProps = {
  siderCollapsed: false,
};

export function getIcon(type: string, contentType?: string) {
  switch (type) {
    case 'instances':
      return <DesktopOutlined />;
    case 'resources':
      return <CloudServerOutlined />;
    case 'fx':
      return <FolderOpenOutlined />;
    case 'dashboards':
      return <DashboardOutlined />;
    case 'applications':
      return <AppstoreOutlined />;
    case 'tasks':
      return <ShoppingOutlined />;
    case 'documentation':
      return contentType?.includes('markdown') ? <FileMarkdownOutlined /> : <FileTextOutlined />;
    case 'springboard-file':
      return <PlayCircleOutlined />;
    default:
      return null;
  }
}

export function getTitle(type: string) {
  const mappedType = type.toUpperCase();
  if (mappedType === 'TASKS') return searchableMenuItemLabelMap['JOBS'];
  return searchableMenuItemLabelMap[mappedType] || type;
}

interface HighlightTextSafeProps {
  text: string;
}

// instead of setDangerouslyInnerHTML (matches in highlights are already wrapped in <em> tags)
function HighlightTextSafe({ text }: HighlightTextSafeProps) {
  const parts = text.split(/<em>(.*?)<\/em>/g);
  return <span>{parts.map((part, index) => (index % 2 === 1 ? <strong key={index}>{part}</strong> : part))}</span>;
}

function buildResultHref(query: string, knownDocsMap: { [key: string]: string }, item: SearchResult): string {
  const filePath = item.metadata?.filePath.toString() as string;
  if (item.metadata?.sourceType === 'normalization') {
    const pathOnly = filePath.substring(0, filePath.lastIndexOf('/')).replace(knownDocsMap[NORMALIZATION_GUIDE_ZOE_PATH], '');
    return `/documentation/${NORMALIZATION_GUIDE_ZOE_PATH}/${pathOnly}/${query}`;
  } else {
    const maybeDocId = filePath?.substring(filePath.indexOf('/') + 1, filePath?.indexOf('/', filePath.indexOf('/') + 1));
    const isDocInZoe = (maybeDocId && Object.values(knownDocsMap).some((knownDocId) => knownDocId.includes(maybeDocId))) || false;
    const isFx = item.metadata?.sourceType?.toString()?.includes('fx');

    if (item?.metadata?.sourceType === 'gitlab' || item?.metadata?.sourceType === 'github') {
      return item.metadata?.webUrl as string;
    } else if (!isDocInZoe) {
      return '#';
    } else {
      let url = '/documentation';
      if (filePath.includes(knownDocsMap[API_CLIENT_USER_GUIDE_ZOE_PATH])) {
        url += `/${API_CLIENT_USER_GUIDE_ZOE_PATH}/${item.metadata?.fileName}`;
      } else if (filePath.includes(knownDocsMap[BELLPORT_DOCUMENTATION_ZOE_PATH])) {
        url += `/${BELLPORT_DOCUMENTATION_ZOE_PATH}`;
      } else if (isFx) {
        return `/file-explorer/mst-drive/sys-public-documents/${item.metadata?.filePath}/${query}`;
      } else {
        url += `${item.metadata?.filePath}`;
      }
      return url;
    }
  }
}

interface GlobalSearchResultProps {
  query: string;
  result: ProviderSearchResults;
  onClickItem: (key: string) => void;
  knownDocsMap: { [key: string]: string };
}

export function GlobalSearchResult({ query, result, onClickItem, knownDocsMap }: GlobalSearchResultProps) {
  const [docsTooltip, setDocsTooltip] = useState('');

  function renderItem(type: string, item: SearchResult): MenuItem {
    let href: string;

    switch (type) {
      case 'instances':
        href = getInstanceUrl(item.id);
        break;
      case 'resources':
        href = `/resources#resource-${item.id}`;
        break;
      case 'dashboards':
        href = `/dashboard/${item.id}`;
        break;
      case 'tasks':
        href = `/jobs/${item.id}`;
        break;
      case 'applications':
        href = `/applications/${item.id}`;
        break;
      case 'fx':
        href = item.id;
        break;
      case 'springboard-file':
        href = `/springboard-browser/#${item.id}`;
        break;
      case 'documentation':
        href = buildResultHref(query, knownDocsMap, item);
        break;
      default:
        href = '';
        break;
    }

    function renderFilePath(result: SearchResult): string {
      if (item?.metadata?.sourceType === 'gitlab' || item?.metadata?.sourceType === 'github') {
        return typeof result.metadata?.webUrl === 'string' ? result.metadata.webUrl : '';
      } else {
        return typeof result.metadata?.filePath === 'string' ? result.metadata.filePath.split('/').slice(0, -1).join('/') : '';
      }
    }

    function onClickHighlight(sectionedHighlight: SectionedHighlight, event?: React.MouseEvent<HTMLDivElement>) {
      event?.stopPropagation();
      const href = buildResultHref(query, knownDocsMap, item);
      const searchParams = new URLSearchParams(window.location.search);

      if (isMarkdownFile(item.metadata?.filePath.toString())) {
        searchParams.set(SEARCH_TERM_PREFIX, query);
      }

      const queryString = searchParams.toString() ? `?${searchParams.toString()}` : '';
      const innerHref = `${href}${queryString}#${sectionedHighlight.sectionId}`;

      onClickItem?.(innerHref);
    }

    const setSectionedDocsTooltip = (highlight: SectionedHighlight) => {
      const href = buildResultHref(query, knownDocsMap, item);
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(SEARCH_TERM_PREFIX, query);
      const queryString = searchParams.toString() ? `?${searchParams.toString()}` : '';
      const innerHref = `${href}${queryString}#${highlight.sectionId}`;
      setDocsTooltip('(section) ' + innerHref);
    };
    const setNotSectionedDocsTooltip = () => {
      const href = buildResultHref(query, knownDocsMap, item);
      setDocsTooltip('(page) ' + href);
    };

    return {
      key: `${type}|${href}`,
      icon: getIcon(type, item?.contentType),
      className: styles.searchResultListItem,
      label: (
        <div
          onMouseEnter={() => {
            const href = buildResultHref(query, knownDocsMap, item);
            setDocsTooltip('(page) ' + href);
          }}
        >
          <Tooltip
            title={
              <>
                Go to {getTitle(type)} {type === 'documentation' && <span className={styles.tooltipUrl}>{docsTooltip}</span>}
              </>
            }
          >
            <div>
              <div className={styles.searchResultListItemHeader}>
                <div className={styles.searchResultListItemTitleAndType}>
                  <span className={styles.searchResultListItemTitle}>{item.title}</span>
                  <span className={styles.searchResultListItemType}>
                    {item.contentType && item.contentType.includes(';') ? item.contentType.substring(0, item.contentType.indexOf(';')) : ''}
                    {item.contentType && !item.contentType.includes(';') ? item.contentType : ''}
                  </span>
                </div>
                <p className={styles.searchResultListItemPath}>{renderFilePath(item)}</p>
              </div>
            </div>

            {/*match(es) found in this file:*/}
            {item.highlights &&
              item.highlights.map((highlight, index) => {
                /*organize match(es) in sections*/
                const isHighlightSectioned = typeof highlight === 'object' && 'texts' in highlight && 'title' in highlight;
                return (
                  <div key={'highlight' + index}>
                    {isHighlightSectioned && (
                      <div
                        className={styles.highlightSection}
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => onClickHighlight(highlight, event)}
                        onKeyDown={(e) => e.key === 'Enter' && onClickHighlight(highlight)}
                        role="button"
                        tabIndex={0}
                        onMouseEnter={() => setSectionedDocsTooltip(highlight)}
                        onMouseLeave={setNotSectionedDocsTooltip}
                      >
                        <h4 className={styles.highlightSectionTitle}>
                          <strong>{highlight.title}</strong>
                        </h4>
                        {highlight.texts.map((text: string, i: number) => (
                          <p className={styles.innerHighlight} key={'innerHighlight|' + i}>
                            {text} {/* leaf for sectioned highlights*/}
                          </p>
                        ))}
                      </div>
                    )}{' '}
                    {!isHighlightSectioned && (
                      <p className={styles.highlight}>
                        {`(${index + 1}) `}
                        <HighlightTextSafe text={highlight} /> {/* leaf for not sectioned highlights*/}
                      </p>
                    )}{' '}
                  </div>
                );
              })}
          </Tooltip>
        </div>
      ),
    };
  }

  const menuItems: MenuItem[] = (result.searchResults || []).map((searchResult) => {
    return renderItem(result.label, searchResult);
  });

  const handleItemClick = useCallback(
    ({ key }: { key: string }) => {
      onClickItem?.(key);
    },
    [onClickItem]
  );

  return (
    <div className={'result-container'}>
      <SiderContext.Provider value={fakeSiderProps}>
        <Menu onClick={handleItemClick} disabled={result.status !== 'loaded'} mode={'vertical'} items={menuItems} />
      </SiderContext.Provider>
    </div>
  );
}
