import { checkAxiosResponse, extractValidationError, MstGenericResponse } from '@mst-fe/shared/dist/errors/axios-errors';
import axios from 'axios';
import { axiosOptions } from '../../services/requests';
import { DEFAULT_DAYS_TO_GO_BACK } from './views/DataLakeStatusMonitorView';

export interface FeedsMonitorQueryParams {
  mode: 'day' | 'range';
  feed: string;
  day?: string;
  dateFrom?: string;
  dateTo?: string;
}

export interface FeedsMonitorData {
  year: string;
  month: string;
  day: string;
  session: string;
}

export interface AirflowStatusItem {
  feed_name: string;
  dag_type: string;
  dag_id: string;
  state: 'success' | 'running' | 'failed' | 'not run' | undefined;
  start_date: string | undefined;
  end_date: string | undefined;
  is_paused: boolean;
  tasks?: {
    task_id: string;
    sla_miss: object | undefined;
    start_date: string | undefined;
    end_date: string | undefined;
  }[];
}

export interface FeedStatus {
  dt: string;
  f: string;
  table: string;
}

export interface DataLakeStatus {
  status: FeedStatus[];
  syncDate: string | undefined;
  running: boolean;
}

export interface AirflowStatus {
  status: AirflowStatusItem[];
  sync_date: string | undefined;
}

export interface StatusDashboardLive {
  type: string;
  table_name: string;
  feedname: string;
  status: string;
  write_datetime: string;
  uploaded_datetime: string;
  difference_minutes: number;
}

export interface StatusDashboardData {
  sync_date: string;
  status_start: string;
  live_status_start: string;
  status: AirflowStatusItem[];
  live_status: StatusDashboardLive[];
}

export class MonitoringApi {
  async getFeedsMonitorFeeds(): Promise<string[] | undefined> {
    return await checkAxiosResponse(axios.get<string[]>('/api/zoe/monitoring-apps/feeds-monitor/feeds', await axiosOptions()));
  }

  async queryFeedsMonitorData(params: FeedsMonitorQueryParams): Promise<FeedsMonitorData[] | undefined> {
    return await checkAxiosResponse(axios.get('/api/zoe/monitoring-apps/feeds-monitor/query', { ...(await axiosOptions()), params }));
  }

  async getAirflowStatus(tags?: string[]): Promise<AirflowStatus | undefined> {
    return await checkAxiosResponse(axios.get('/api/zoe/monitoring-apps/airflow/status', { ...(await axiosOptions()), params: { tags } }));
  }

  async getDataLakeStatus(daysToGoBack: number | null | undefined): Promise<DataLakeStatus | undefined> {
    if (!daysToGoBack) {
      daysToGoBack = DEFAULT_DAYS_TO_GO_BACK;
    }
    return await checkAxiosResponse(
      axios.get(`/api/zoe/monitoring-apps/data-lake/status?daysToGoBack=${daysToGoBack}`, await axiosOptions())
    );
  }

  async refreshDataLakeStatus(): Promise<MstGenericResponse | undefined> {
    return await extractValidationError(axios.post(`/api/zoe/monitoring-apps/data-lake/force-refresh`, {}, await axiosOptions()));
  }
}
