import React from 'react';
import { resolvePath } from 'react-router-dom';
import { ExtraProps } from 'react-markdown';
import { DocumentationMdComponent, DocumentationMdProps } from '../MarkdownDocumentation';

export function ImgComponent({
  node,
  mdPage,
  docEndpoint,
  src,
  ...props
}: React.ClassAttributes<HTMLImageElement> &
  React.ImgHTMLAttributes<HTMLImageElement> &
  ExtraProps &
  DocumentationMdProps): DocumentationMdComponent {
  if (!src) return;

  // Path is relative to the current location, resolve it on the api server endpoint
  const apiUrl = `${docEndpoint}/${mdPage.filePath}`;
  src = resolvePath('../' + src, apiUrl).pathname;

  return <img src={src} alt={mdPage.filePath} {...props} />;
}
