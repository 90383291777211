import { theme } from 'antd';

export default function getAntDTheme(selectedTheme: 'light' | 'dark') {
  return {
    algorithm: selectedTheme === 'light' ? theme.defaultAlgorithm : theme.darkAlgorithm,
    token: {
      colorBgLayout: 'transparent',
      //borderRadius: 0,
    },
  };
}
