import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { AppThemeContext } from '../../../hooks/use-theme';

import { DocumentationModel } from '../models/documentation-list';

import styles from './DocumentationScreen.module.css';

// docs zoe path
export const API_CLIENT_USER_GUIDE_ZOE_PATH = 'api-client-user-guide';

export const BELLPORT_DOCUMENTATION_ZOE_PATH = 'rtud-documentation';

export const NORMALIZATION_GUIDE_ZOE_PATH = 'normalization-guide';

// docs folders, receved from BE
export const API_CLIENT_USER_GUIDE_FOLDER = '/maystreet-data-library-documentation/';

export const BELLPORT_DOCUMENTATION_FOLDER = '/bellport-documentation/';

export const NORMALIZATION_GUIDE_FOLDER = 'normalization-guide/';

//map between docs folders and docs zoe path
export const KNOWN_ZOE_DOCS_PATH_MAP: { [key: string]: string } = {
  [API_CLIENT_USER_GUIDE_ZOE_PATH]: API_CLIENT_USER_GUIDE_FOLDER,
  [BELLPORT_DOCUMENTATION_ZOE_PATH]: BELLPORT_DOCUMENTATION_FOLDER,
  [NORMALIZATION_GUIDE_ZOE_PATH]: NORMALIZATION_GUIDE_FOLDER,
};

//map docs zoe path and their navigability
export const KNOWN_IS_NAVIGABLE_ZOE_DOCS_PATH_MAP: { [key: string]: boolean } = {
  [API_CLIENT_USER_GUIDE_ZOE_PATH]: true,
  [BELLPORT_DOCUMENTATION_ZOE_PATH]: false,
  [NORMALIZATION_GUIDE_ZOE_PATH]: true,
};

export default function DocumentationScreen({ model }: { model: DocumentationModel }) {
  const { theme } = useContext(AppThemeContext);

  const { documentationId } = useParams();

  const location = useLocation();
  const [iframeSrc, setIframeSrc] = useState<string>('');

  const documentationItem = useMemo(
    () => model.documentation.find(({ id }) => documentationId === id),
    [model.documentation, documentationId]
  );
  const uri = useMemo(() => {
    if (!documentationItem) return null;
    return new URL(documentationItem.url);
  }, [documentationItem]);

  /* given a location.pathname starting from a known uri in zoe, navigate within main pages inside that docs */
  useEffect(() => {
    if (!uri) return;

    if (documentationId === API_CLIENT_USER_GUIDE_ZOE_PATH) {
      uri.searchParams.set('colour', theme);
    }

    if (Object.keys(KNOWN_ZOE_DOCS_PATH_MAP).some((el) => location.pathname.includes(el) && KNOWN_IS_NAVIGABLE_ZOE_DOCS_PATH_MAP[el])) {
      const fullNewPath = location.pathname.split('/');
      const targetPageWithinDocs = fullNewPath[fullNewPath.length - 1];

      if (targetPageWithinDocs && targetPageWithinDocs !== API_CLIENT_USER_GUIDE_ZOE_PATH) {
        // eslint-disable-next-line no-inner-declarations
        function getIframeSrc(uri: string | URL, targetPageWithinDocs: string): string {
          const url = new URL(uri.toString());
          const basePath = url.pathname.substring(0, url.pathname.lastIndexOf('/'));
          const newUrl = new URL(`${url.origin}${basePath}/${targetPageWithinDocs}`);
          newUrl.search = url.search; //keep params (like colour)
          return newUrl.toString();
        }

        setIframeSrc(getIframeSrc(uri, targetPageWithinDocs));
      } else {
        setIframeSrc(uri.toString());
      }
    } else {
      setIframeSrc(uri.toString());
    }
  }, [location, uri, theme, documentationId]);

  if (!documentationItem) {
    return <div>Documentation not available.</div>;
  }

  return <iframe className={styles.iframe} src={iframeSrc.toString()} title="Documentation Viewer" />;
}
