import { AxiosRequestConfig } from 'axios';
import { EventSource } from 'eventsource';

import { getAccessToken } from './auth';

export async function axiosOptions(): Promise<AxiosRequestConfig> {
  const token = await getAccessToken();
  return { validateStatus: null, headers: { Authorization: `Bearer ${token}` } };
}

export async function blobAxiosOptions(): Promise<AxiosRequestConfig> {
  const token = await getAccessToken();
  return { responseType: 'blob', validateStatus: null, headers: { Authorization: `Bearer ${token}` } };
}

export async function initEventSourceEndpoint(url: string) {
  const token = await getAccessToken();

  const es = new EventSource(url, {
    fetch: (input, init) =>
      fetch(input, {
        ...init,
        headers: {
          ...init?.headers,
          Authorization: `Bearer ${token}`,
        },
      }),
  });

  es.addEventListener('error', (error) => {
    if (error.code === 401 || error.code === 403) {
      console.error('SSE request not authorized', { url, error });
    } else {
      console.error('SSE request error', { url, error });
    }
  });
  return es;
}
