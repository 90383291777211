import uniq from 'lodash/uniq';
import { DataLakeStatus } from '../monitoring-api';
import { DEFAULT_DAYS_TO_GO_BACK } from '../views/DataLakeStatusMonitorView';

export interface DataLakeMonitorStore {
  state: 'loading' | 'ready';
  data: DataLakeStatus | undefined;
  tables: string[] | undefined;
  selectedTables: string[] | undefined;
  running: boolean;
  daysToGoBack?: number | null;
}

export function initialDataLakeMonitorStore(): DataLakeMonitorStore {
  return {
    state: 'loading',
    data: undefined,
    tables: undefined,
    selectedTables: undefined,
    daysToGoBack: DEFAULT_DAYS_TO_GO_BACK,
    running: false,
  };
}

export function setDataLakeStoreLoading(model: DataLakeMonitorStore): DataLakeMonitorStore {
  return { ...model, state: 'loading' };
}

export function setDataLakeStoreRunning(model: DataLakeMonitorStore): DataLakeMonitorStore {
  return { ...model, running: true };
}

export function updateDataLakeMonitorStore(model: DataLakeMonitorStore, data: DataLakeStatus): DataLakeMonitorStore {
  const tables = uniq((data?.status || []).map(({ table }) => table.toLowerCase())).sort();

  // if selected tables is undefined, then we default them to all the tables.
  const selectedTables = model.selectedTables !== undefined ? model.selectedTables?.filter((table) => tables.includes(table)) : tables;

  return { ...model, data, state: 'ready', tables, selectedTables, running: data.running };
}

export function updateDataLakeSelectedTables(model: DataLakeMonitorStore, tables: string[]): DataLakeMonitorStore {
  const allTables = model.tables || [];
  const selectedTables = tables?.filter((table) => allTables.includes(table));
  return { ...model, selectedTables };
}

export function updateDataLakeDaysToGoBack(model: DataLakeMonitorStore, daysToGoBack: number | null | undefined): DataLakeMonitorStore {
  return { ...model, daysToGoBack };
}
