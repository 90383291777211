import { WbUserTablesRow } from '@mst-fe/shared';
import { checkAxiosResponse, throwAxiosError } from '@mst-fe/shared/dist/errors/axios-errors';
import axios from 'axios';
import { axiosOptions } from '../../services/requests';
import { ROOT_SERVER_URL } from '../utils/midas-constants';

export interface Column {
  Name: string | undefined;
  Type?: string;
  Comment?: string;
  Parameters?: Record<string, string>;
}

// from user-data-lake-service.ts
export type ApiResponse = {
  ok: boolean;
  statusCode: number;
  error?: string;
};

interface ValidateColumnsResponse extends ApiResponse {
  data: {
    glueColumns: Column[];
  };
}

interface CreateTableResponse extends ApiResponse {
  data: {
    parquetSchemaErrors: string[];
  };
}

interface CreateUploadUrlResponse extends ApiResponse {
  data: {
    uploadUrl: string;
    uploadId: string;
    tableId: string;
  };
}

interface GetTablePermissionsResponse extends ApiResponse {
  data: {
    permissions: TablePermissions;
  };
}

type UDTGetTableResponse =
  | { ok: true; statusCode: number; data: { table: WbUserTablesRow | undefined } }
  | { ok: false; statusCode: number; error: string };

export type TablePermissions = {
  isPrivate: boolean;
  sharedWith: { userSubject: string; hasWritePermissions: boolean }[];
};

export interface UserDefinedTableOverview {
  id: string;
  name: string;
  access: 'NONE' | 'WRITE' | 'READ';
  canShare: boolean;
  status: ResourceStatus;
  users: string[];
  owned: boolean;
  isPrivate: boolean;
  createdBy: string;
}

export type ResourceStatus = 'PROVISIONING' | 'REMOVING' | 'PROVISIONING_ERROR' | 'REMOVING_ERROR' | 'PROVISIONED' | 'DELETED';

const userDataLakeApiPath = '/api/user-data-lake';

export class UserDataLakeApi {
  async getOwnedTable(params: { id: string }) {
    const target = new URL(`${userDataLakeApiPath}/tables/${params.id}`, ROOT_SERVER_URL);
    return await throwAxiosError(axios.get<UDTGetTableResponse>(target.toString(), await axiosOptions()));
  }

  buildListTablesUrl(params: { includePublicData?: boolean }) {
    const target = new URL(`${userDataLakeApiPath}/tables`, ROOT_SERVER_URL);

    if (params.includePublicData !== undefined) {
      target.searchParams.set('includePublicData', Boolean(params.includePublicData).toString());
    }

    return target;
  }

  async inferGlueType(params: { columns: { [key: string]: string } }) {
    const target = new URL(`${userDataLakeApiPath}/infer-glue-type`, ROOT_SERVER_URL);
    const res = await axios.put<ValidateColumnsResponse>(target.toString(), { columns: params.columns }, await axiosOptions());

    if ([400, 200].includes(res.status)) {
      return res.data;
    } else {
      return undefined;
    }
  }

  async getUploadUrl(params: { workflowId: string }) {
    const target = new URL(`${userDataLakeApiPath}/workflows/${params.workflowId}/upload-url`, ROOT_SERVER_URL);
    return checkAxiosResponse(axios.get<CreateUploadUrlResponse>(target.toString(), await axiosOptions()));
  }

  async notifyUploadSuccess(params: { uploadId: string }) {
    const target = new URL(`${userDataLakeApiPath}/uploads/${params.uploadId}/upload-success`, ROOT_SERVER_URL);
    return checkAxiosResponse(axios.put<ApiResponse>(target.toString(), undefined, await axiosOptions()));
  }

  async deleteUploadedData(params: { workflowId: string }) {
    const target = new URL(`${userDataLakeApiPath}/workflows/${params.workflowId}`, ROOT_SERVER_URL);
    return checkAxiosResponse(axios.delete<ApiResponse>(target.toString(), await axiosOptions()));
  }

  async createTable(params: { id: string; name: string; columns: { [key: string]: string } }) {
    const target = new URL(`${userDataLakeApiPath}/tables/${params.id}`, ROOT_SERVER_URL);
    const res = await axios.put<CreateTableResponse>(
      target.toString(),
      { tableName: params.name, columns: params.columns },
      await axiosOptions()
    );

    if ([400, 200].includes(res.status)) {
      return res.data;
    } else {
      return undefined;
    }
  }

  async deleteTable(params: { id: string }) {
    const target = new URL(`${userDataLakeApiPath}/tables/${params.id}`, ROOT_SERVER_URL);
    return await throwAxiosError(axios.delete<ApiResponse>(target.toString(), await axiosOptions()));
  }

  async renameTable(params: { id: string; name: string }) {
    const target = new URL(`${userDataLakeApiPath}/tables/${params.id}/rename`, ROOT_SERVER_URL);
    const res = await axios.put<ApiResponse>(target.toString(), { tableName: params.name }, await axiosOptions());
    return res.data;
  }

  async getTablePermissions(params: { id: string }) {
    const target = new URL(`${userDataLakeApiPath}/tables/${params.id}/permissions`, ROOT_SERVER_URL);
    return await throwAxiosError(axios.get<GetTablePermissionsResponse>(target.toString(), await axiosOptions()));
  }

  async setTablePermissions(params: { id: string; permissions: TablePermissions }) {
    const target = new URL(`${userDataLakeApiPath}/tables/${params.id}/permissions`, ROOT_SERVER_URL);
    return await throwAxiosError(
      axios.put<GetTablePermissionsResponse>(target.toString(), { permissions: params.permissions }, await axiosOptions())
    );
  }
}
