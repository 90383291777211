import axios from 'axios';

import { checkAxiosResponse, handleAxiosError } from '@mst-fe/shared/dist/errors/axios-errors';
import { LinkedError } from '@mst-fe/shared/dist/errors/linked-error';

import { axiosOptions } from '../../../services/requests';

import { NotificationItem } from './models/notification';

export class NotificationsApi {
  async getOutstandingNotificationsAfterId(notificationId: number) {
    // protect against injection!
    return await checkAxiosResponse(
      axios.get<NotificationItem[]>(`/api/zoe/notifications/outstanding?id=${parseInt(notificationId.toString())}`, await axiosOptions())
    );
  }

  async setNotificationDismissed(id?: number): Promise<boolean> {
    const responseOrError = await handleAxiosError(
      axios.post<{ ok: true }>(`/api/zoe/notifications/set-read`, id ? { id } : null, { ...(await axiosOptions()), timeout: 10 * 1000 })
    );

    if (responseOrError instanceof LinkedError) {
      return false;
    }

    return responseOrError.ok;
  }

  async setNotificationsSeen(ids: number[]): Promise<boolean> {
    const responseOrError = await handleAxiosError(
      axios.post<{ ok: true }>(`/api/zoe/notifications/set-seen`, { ids }, { ...(await axiosOptions()) })
    );

    if (responseOrError instanceof LinkedError) {
      return false;
    }

    return responseOrError.ok;
  }
}
