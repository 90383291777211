import React, { useEffect, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Modal, Radio, RadioChangeEvent, Result, Spin, Tooltip } from 'antd';

import { User, UsersApi } from '../../../pages/users/users-api';
import { collapseUsers } from '../../../pages/users/utils';
import { getUserSubject } from '../../../services/auth';

import { TablePermissions, UserDataLakeApi } from '../../api/user-data-lake-api';

import UserPermissions from './UserPermissions';

import styles from './ModifyUDTPermissionsDialog.module.css';

const MAX_PERMISSIONS = 40;

type ModifyPermissionsDialogProps = {
  modalState: {
    open: boolean;
    tableId: string;
    disabled: boolean;
  };
  setModalState: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      tableId: string;
      disabled: boolean;
    }>
  >;
  runUpdate: () => void;
};

export default function ModifyUDTPermissionsDialog(props: ModifyPermissionsDialogProps) {
  const [userSubject, setUserSubject] = useState('');
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState('');
  const [permissions, setPermissions] = useState<TablePermissions>({ isPrivate: true, sharedWith: [] });
  const [submitError, setSubmitError] = useState('');

  const usersApi = useMemo(() => new UsersApi(), []);
  const userDataLakeApi = useMemo(() => new UserDataLakeApi(), []);

  useEffect(() => {
    async function getPermissions() {
      if (props.modalState.tableId === '') {
        return;
      }

      try {
        const user = await getUserSubject();
        if (!user) {
          throw new Error('Sorry, something went wrong.');
        }

        setUserSubject(user);

        const users = await usersApi.getUsers();
        setAllUsers(collapseUsers(users?.allUserGroups ?? []));

        const apiRes = await userDataLakeApi.getTablePermissions({ id: props.modalState.tableId });
        if (!apiRes.ok) {
          throw new Error(apiRes.error);
        }

        setLoadingError('');
        setPermissions(apiRes.data.permissions);
      } catch (err: unknown) {
        const errMessage = err instanceof Error ? err.message : 'Sorry, something went wrong.';
        setLoadingError(errMessage);
        props.setModalState({ ...props.modalState, disabled: true });
        console.error(err);
      }
      setLoading(false);
    }

    getPermissions().catch((e) => console.error(e));
  }, [props.modalState.tableId, userDataLakeApi, usersApi]);

  const handleOk = async () => {
    setLoading(true);

    try {
      await userDataLakeApi.setTablePermissions({ id: props.modalState.tableId, permissions });
      setSubmitError('');
      props.runUpdate();
      props.setModalState({ open: false, tableId: '', disabled: true });
    } catch (err: unknown) {
      const errMessage = err instanceof Error ? err.message : 'Sorry, something went wrong.';
      setSubmitError(errMessage);
      console.error(err);
    }
    setLoading(false);
  };

  const visibilityChangeHandler = (e: RadioChangeEvent) => {
    setPermissions({ ...permissions, isPrivate: e.target.value as boolean });
  };

  const addPermissionClickHandler = () => {
    setPermissions({ ...permissions, sharedWith: [...permissions.sharedWith, { userSubject: '', hasWritePermissions: false }] });
  };

  const userChangeHandler = (index: number, userSubject: string) => {
    setPermissions({
      isPrivate: permissions.isPrivate,
      sharedWith: permissions.sharedWith.map((v, i) => (i === index ? { ...v, userSubject } : v)),
    });
  };

  const writeChangeHandler = (index: number, checked: boolean) => {
    setPermissions({
      isPrivate: permissions.isPrivate,
      sharedWith: permissions.sharedWith.map((v, i) => (i === index ? { ...v, hasWritePermissions: checked } : v)),
    });
  };

  const deletePermissionsRowHandler = (index: number) => {
    setPermissions({
      isPrivate: permissions.isPrivate,
      sharedWith: permissions.sharedWith.filter((_v, i) => i !== index),
    });
  };

  const isValid = !permissions.sharedWith.some((p) => !p.userSubject);

  return (
    <Modal
      open={props.modalState.open}
      okButtonProps={{ disabled: props.modalState.disabled || !isValid }}
      okText="Submit"
      onOk={handleOk}
      onCancel={() => props.setModalState({ open: false, tableId: '', disabled: true })}
      destroyOnClose
      maskClosable={false}
      title="Table Permissions"
      width={600}
    >
      {loadingError ? (
        <Result status="500" title="Unexpected Error" subTitle={loadingError} />
      ) : (
        <Spin spinning={loading} size="large" tip="Loading...">
          {!props.modalState.disabled && (
            <Flex vertical>
              <div>
                {`Update the table's permissions. If a table is public, all the users have read permissions to it. 
            `}
              </div>
              <div>{`In addition, you can set permissions at the user-level, including write permissions, up to ${MAX_PERMISSIONS}. 
            User-level permissions override the visibility setting. For instance, a private table is still visible to a user with specific permissions.`}</div>
            </Flex>
          )}
          <Flex className={styles.flexForm} vertical gap="small">
            <Form.Item
              label={<Tooltip title="visibility">Visibility</Tooltip>}
              name="permissions"
              valuePropName="isPrivate"
              labelAlign="left"
              labelCol={{ span: 4 }}
              className={styles.radioGroupForm}
            >
              <Radio.Group
                disabled={props.modalState.disabled}
                value={permissions.isPrivate}
                onChange={visibilityChangeHandler}
                name="visibilityGroup"
              >
                <Radio value={true}>Private</Radio>
                <Radio value={false}>Public</Radio>
              </Radio.Group>
            </Form.Item>
            {permissions.sharedWith.map((s, index) => {
              const selectedUser = allUsers.find((u) => u.value === s.userSubject);

              return (
                <UserPermissions
                  key={index}
                  index={index}
                  allUsers={allUsers.filter(
                    (u) => u.value !== userSubject && !permissions.sharedWith.some((p) => p.userSubject === u.value)
                  )}
                  selectedUser={selectedUser === undefined ? undefined : { value: selectedUser.value, label: selectedUser.name }}
                  hasWritePermissions={s.hasWritePermissions}
                  userChangeHandler={userChangeHandler}
                  writeChangeHandler={writeChangeHandler}
                  deleteHandler={deletePermissionsRowHandler}
                  disabled={props.modalState.disabled}
                />
              );
            })}
            {!props.modalState.disabled && (
              <Button
                disabled={permissions.sharedWith.length >= MAX_PERMISSIONS}
                icon={<PlusOutlined />}
                onClick={addPermissionClickHandler}
                style={{ width: '6em' }}
              >
                Add
              </Button>
            )}
            {!isValid && <div className={styles.errorText}>Please assign a user to every permission.</div>}
            {submitError !== '' && <div className={styles.errorText}>{submitError}</div>}
          </Flex>
        </Spin>
      )}
    </Modal>
  );
}
