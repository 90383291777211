import { useEffect, useState } from 'react';

export function useController<M, C>(initialModel: () => M, Controller: { new (_: (_: M) => void, m?: M): C & { dispose?(): void } }) {
  const [model, setModel] = useState<M>(initialModel);
  const [delegate, setDelegate] = useState<C | undefined>();

  useEffect(() => {
    const localDelegate = new Controller(setModel, model);
    setDelegate(localDelegate);

    return () => localDelegate.dispose?.();
  }, [Controller, setModel, setDelegate]);

  return { model, delegate };
}
