import { handleError } from '../../services/error-notification';
import { User, UsersApi } from '../users/users-api';
import { collapseUsers } from '../users/utils';
import { serviceFromData, WbService } from './models/service';
import {
  deleteService,
  deleteServiceCancelled,
  deployService,
  initialAppList,
  initialConfirmServiceAction,
  initialConfirmShareServiceAction,
  ServicesListModel,
  shareService,
  shareServiceCancelled,
  stopService,
  stopServiceCancelled,
  uninstallService,
  uninstallServiceCancelled,
  updateServices,
} from './models/services-list';
import { ServicesApi, WbServiceData } from './services-api';
import { ServiceDetailsDelegate } from './views/ServiceDetails';

export class ServicesController implements ServiceDetailsDelegate {
  private api = new ServicesApi();

  private usersApi = new UsersApi();

  private model = initialAppList();

  private es: EventSource | undefined = undefined;

  constructor(private updateViewState: (_: ServicesListModel) => void) {}

  start() {
    if (!this.es) {
      void (async () => {
        this.es = await this.initEventSourceEndpoint();
      })();
    }
  }

  dispose() {
    this.es?.close();
  }

  private async restartInterval() {
    this.es?.close();

    this.es = await this.initEventSourceEndpoint();
  }

  private async initEventSourceEndpoint() {
    const es = await this.api.initUserServicesInterval();

    es.addEventListener('message', (event) => {
      const response = typeof event.data === 'string' ? (JSON.parse(event.data) as WbServiceData[]) : undefined;

      if (response) {
        const value = response.map((data) => serviceFromData(this.model.services, data));
        this.update(updateServices(this.model, value));
      }
    });
    return es;
  }

  private getModel = (): ServicesListModel => this.model;

  private update = (model: ServicesListModel) => {
    this.model = model;
    this.updateViewState(model);
  };

  async onDeployService(service: WbService): Promise<void> {
    await deployService(this.api, this.model, service.id);
  }

  onStopService(app: WbService): void {
    this.update({ ...this.model, confirmStopService: initialConfirmServiceAction(app) });
  }

  async onStopServiceSelected(stop: boolean): Promise<void> {
    if (stop) {
      handleError(await stopService(this.api, this.getModel, this.update, this.restartInterval.bind(this)));
    } else {
      this.update(stopServiceCancelled(this.model));
    }
  }

  async onShareService(service: WbService): Promise<void> {
    let users: User[] = [];
    if (!service.shortId) {
      users = collapseUsers((await this.usersApi.getUsers())?.allUserGroups ?? []);
    } else {
      const usersRes = (await this.api.getAuthorizedUsers(service.shortId)) || [];
      users = usersRes.map((u) => ({ name: u.name, value: u.id, email: u.email, title: `${u.name} (${u.email})`, selectable: true }));
    }
    this.update({ ...this.model, confirmShareService: initialConfirmShareServiceAction(service, users) });
  }

  async onShareServiceSelected(share: boolean): Promise<void> {
    if (share) {
      handleError(await shareService(this.api, this.getModel, this.update, this.restartInterval.bind(this)));
    } else {
      this.update(shareServiceCancelled(this.model));
    }
  }

  onShareServiceChangePermissionType(type: 'just-me' | 'organization' | 'users'): void {
    if (!this.model.confirmShareService) {
      return;
    }
    this.update({ ...this.model, confirmShareService: { ...this.model.confirmShareService, type: type } });
  }

  onShareServiceSelectUsers(users: string[]) {
    if (!this.model.confirmShareService) {
      return;
    }
    this.update({ ...this.model, confirmShareService: { ...this.model.confirmShareService, selectedUsersIds: users } });
  }

  onDeleteService(service: WbService): void {
    this.update({ ...this.model, confirmRemoveService: initialConfirmServiceAction(service) });
  }

  onUninstallService(service: WbService): void {
    this.update({ ...this.model, confirmUninstallService: initialConfirmServiceAction(service) });
  }

  async onDeleteServiceSelected(remove: boolean, navigate: (target: string) => void): Promise<void> {
    if (remove) {
      const error = await deleteService(this.api, this.getModel, this.update, this.restartInterval.bind(this));
      if (error) {
        handleError(error);
      } else {
        navigate('/');
      }
    } else {
      this.update(deleteServiceCancelled(this.model));
    }
  }

  async onUninstallServiceSelected(uninstall: boolean, navigate: (target: string) => void): Promise<void> {
    if (uninstall) {
      const error = await uninstallService(this.api, this.getModel, this.update, this.restartInterval.bind(this));
      if (error) {
        handleError(error);
        this.update(uninstallServiceCancelled(this.model));
      } else {
        navigate('/');
      }
    } else {
      this.update(uninstallServiceCancelled(this.model));
    }
  }
}
