import React, { useEffect, useMemo, useState } from 'react';

import { Flex, Form, Input, Modal, Spin, Tooltip } from 'antd';

import { UserDataLakeApi } from '../../api/user-data-lake-api';

type RenameTableDialogProps = {
  modalState: {
    open: boolean;
    tableId: string;
    tableName: string;
  };
  setModalState: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      tableId: string;
      tableName: string;
    }>
  >;
  runUpdate: () => void;
};

export default function RenameUDTDialog(props: RenameTableDialogProps) {
  const [loading, setLoading] = useState(true);
  const [submitError, setSubmitError] = useState('');
  const [name, setName] = useState('');

  const userDataLakeApi = useMemo(() => new UserDataLakeApi(), []);

  useEffect(() => {
    setName(props.modalState.tableName);
    setLoading(false);
  }, [props.modalState.tableId, props.modalState.tableName, setName]);

  const handleOk = async () => {
    setLoading(true);
    try {
      const apiRes = await userDataLakeApi.renameTable({ id: props.modalState.tableId, name });
      if (!apiRes?.ok) {
        throw new Error(apiRes.error);
      }
      setSubmitError('');
      props.runUpdate();
      props.setModalState({ open: false, tableId: '', tableName: '' });
    } catch (err: unknown) {
      const errMessage = err instanceof Error ? err.message : 'Sorry, something went wrong.';
      setSubmitError(errMessage);
      console.error(err);
    }
    setLoading(false);
  };

  return (
    <Modal
      open={props.modalState.open}
      okText="Rename"
      onOk={handleOk}
      onCancel={() => props.setModalState({ open: false, tableId: '', tableName: '' })}
      destroyOnClose
      maskClosable={false}
      title="Rename Table"
      width={600}
    >
      <Spin spinning={loading} size="large" tip="Loading...">
        <Flex vertical gap="small">
          <Form.Item label={<Tooltip title="name">Name</Tooltip>} name="name" valuePropName="name" labelAlign="left">
            <Input value={name} onChange={(e) => setName(e.target.value)}></Input>
          </Form.Item>
          {submitError !== '' && <div className="text-red">{submitError}</div>}
        </Flex>
      </Spin>
    </Modal>
  );
}
