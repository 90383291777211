import { notification } from 'antd';
import { GlobalConfigProps } from 'antd/lib/notification/interface';
import { sanitize } from 'dompurify';
import React from 'react';
import { ErrorNotification } from '../../services/error-notification';

export const NOTIFICATION_TOP = 86;

export interface NotificationMessage {
  type: 'success' | 'error' | 'info' | 'open';
  message: string;
  key?: string;
  description?: string;
  config?: GlobalConfigProps;
  duration?: number;
  btn?: React.ReactNode;
  onClick?: (event?: MouseEvent) => void;
  onClose?: () => void;
}

export function showNotification({ type, message, description, key, config, duration, btn, onClick, onClose }: NotificationMessage) {
  const sanitizedDescription = sanitize(description ?? '');

  notification.config(
    config ?? {
      top: NOTIFICATION_TOP,
    }
  );
  const openNotification = () =>
    notification[type]({
      key,
      message,
      description: <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }}></div>,
      duration: duration || 5,
      btn,
      onClose,
      onClick,
      style: onClick
        ? {
            cursor: 'pointer',
            whiteSpace: 'pre-line',
          }
        : {
            whiteSpace: 'pre-line',
          },
      className: onClick ? 'clickable-notification' : undefined,
    });

  if (!document.hidden) {
    openNotification();
  } else {
    const t = setInterval(() => {
      if (!document.hidden) {
        openNotification();
        clearInterval(t);
      }
    }, 1000);
  }
}

export function showErrorNotification({ message, description, deduplicationKey }: ErrorNotification) {
  showNotification({ type: 'error', message, description, key: deduplicationKey });
}

export function showNotifications(notifications: NotificationMessage[]) {
  for (const n of notifications) {
    showNotification(n);
  }
}
