import React, { useCallback } from 'react';
import { Form, Modal, Space, Switch } from 'antd';
import { UserPreferencesModel } from '../models/user-preferences';
import { Spinner } from '../../../_shared/Spinner';

import styles from './UserPreferencesDialog.module.css';
import useResponsive from '../../../hooks/use-responsive';

export interface UserPreferencesDelegate {
  onOpenUserPreferences(): void;

  onCancelUserPreferences(): void;

  onSaveUserPreferences(): void;

  onEditStaleInstancesAlertsEnabled(checked: boolean): void;
}

export function UserPreferencesDialog({ model, delegate }: { model: UserPreferencesModel; delegate: UserPreferencesDelegate }) {
  const handleCancel = useCallback(() => delegate.onCancelUserPreferences(), [delegate]);
  const handleConfirm = useCallback(() => delegate.onSaveUserPreferences(), [delegate]);

  const { isMobile } = useResponsive();

  const {
    branding: { longName },
  } = window.CONFIG;

  return (
    <Modal title="Preferences" maskClosable={false} open onCancel={handleCancel} onOk={handleConfirm} okText="Save" destroyOnClose>
      {model.loading && <Spinner />}

      {!model.loading && (
        <Space direction="vertical" className="w-full" size="middle">
          <div>Please note these preferences apply to all {longName} instances.</div>
          <div className={styles.preferencesContainer}>
            <strong>Notifications</strong>
            <div>
              With this option enabled, alerts will be sent via email to your email address when any instance is approaching its idle
              timeout and when it is automatically paused.
            </div>
            <Form.Item label="Stale instance email alerts" className={styles.preferenceItem}>
              <Switch
                id="wb_stale_emails_enabled"
                aria-label="Stale instance email alerts enabled?"
                checked={model.preferences?.staleInstancesAlertsEnabled}
                onChange={(checked) => delegate.onEditStaleInstancesAlertsEnabled(checked)}
              />
            </Form.Item>
          </div>
        </Space>
      )}
    </Modal>
  );
}
