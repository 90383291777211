import { ClockCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, TimePicker } from 'antd';
import classnames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useId, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import { PreciseTime } from '../../pages/types';

import classes from './PreciseTimePicker.module.css';

dayjs.extend(customParseFormat);

interface PreciseTimePickerProps {
  time?: string;
  onTimeChange: (values: PreciseTime) => void;
  placeholder?: string;
  withMilliseconds?: boolean;
  withMicroseconds?: boolean;
  americanFormat?: boolean;
  validator?: (values: any) => boolean;
  errorMessage?: React.ReactNode | ((values: any) => React.ReactNode);
  disabled?: boolean;
  autofillMissingFields?: boolean | string;
  style?: React.CSSProperties | undefined;
}

const addEventListener = <K extends keyof WindowEventMap>(eventName: K, handler: (event: WindowEventMap[K]) => void) => {
  window.addEventListener(eventName, handler);
  return () => window.removeEventListener(eventName, handler);
};

const getTimeMask = (value: string, withMilliseconds: boolean, withMicroseconds: boolean, americanFormat: boolean) => {
  const thousandMask = [' ', '.', /[0-9]/, /[0-9]/, /[0-9]/];
  const millisecondsMask = withMilliseconds ? thousandMask : [];
  const microsecondsMask = millisecondsMask.length && withMicroseconds ? thousandMask : [];

  const dateMask = americanFormat
    ? [/[0-1]/, value[0] === '1' ? /[0-2]/ : /[1-9]/, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ' ', /[apAP]/, 'M']
    : [/[0-2]/, value[0] === '2' ? /[0-3]/ : /[0-9]/, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];

  return [...dateMask, ...millisecondsMask, ...microsecondsMask];
};

const convertTo24Hours = (h: number, period: string) => h - (h === 12 ? 12 : 0) + (period === 'PM' ? 12 : 0);

const getNumberOrNan = (str: string) => (str.includes('_') ? NaN : Number.parseInt(str, 10));

const getCalculatedTimeValues = (currentValue: string, validator = (_: PreciseTime) => true): PreciseTime => {
  const americanPeriod = currentValue.includes('M') ? currentValue.slice(9, 11) : undefined;
  const addedLetters = americanPeriod ? 3 : 0;
  const rawHours = getNumberOrNan(currentValue.slice(0, 2));
  const hours = americanPeriod ? convertTo24Hours(rawHours, americanPeriod) : rawHours;
  const minutes = getNumberOrNan(currentValue.slice(3, 5));
  const seconds = getNumberOrNan(currentValue.slice(6, 8));
  const milliseconds = getNumberOrNan(currentValue.slice(10 + addedLetters, 13 + addedLetters));
  const microseconds = getNumberOrNan(currentValue.slice(15 + addedLetters, 18 + addedLetters));
  // if milli/microsecond is NaN it also gives an information, so they are not instantly set to 0
  const timestamp = (hours * 3600 + minutes * 60 + seconds) * 1000 + (milliseconds || 0);
  const americanPeriodCleared = !americanPeriod || americanPeriod === '_M';
  const isCleared = currentValue.split('').every((char) => Number.isNaN(Number.parseInt(char, 10))) && americanPeriodCleared;
  const hourIsSet = [hours, minutes, seconds].every((val) => Number.isInteger(val)) && americanPeriod !== '_M';

  const values = {
    timestamp,
    microTimestamp: timestamp * 1000 + (microseconds || 0),
    timeString: currentValue,
    hours,
    minutes,
    seconds,
    milliseconds,
    microseconds,
    americanPeriod,
    isCleared,
    hourIsSet,
  } as PreciseTime;

  return { ...values, isValid: validator(values) };
};

const getAutofilledValue = (timeString: string, autofillMissingFields: boolean | string, americanFormat: boolean) => {
  if (!autofillMissingFields) {
    return undefined;
  }

  const autofillSplitIndex = autofillMissingFields === 'withPrecision' ? 99 : timeString.indexOf('.');
  const currentHourString = timeString.substring(0, autofillSplitIndex);
  const precisionSuffix = timeString.substring(autofillSplitIndex);

  const requiresAutofill = currentHourString.includes('_') && /\d|A|P/.test(currentHourString);
  if (!requiresAutofill) {
    return undefined;
  }

  let autofilledValue = currentHourString.replace('_M', 'AM').replace(/_/g, '0') + precisionSuffix;
  if (americanFormat && autofilledValue.substring(0, 2) === '00') {
    autofilledValue = autofilledValue.replace('00', '12');
  }
  return autofilledValue;
};

const DEFAULT_PROPS = {
  validator: ({ hourIsSet, isCleared }: { hourIsSet: boolean; isCleared: boolean }) => hourIsSet || isCleared,
};

export default function PreciseTimePicker({
  time = '',
  onTimeChange,
  placeholder,
  withMilliseconds = true,
  withMicroseconds = true,
  americanFormat = false,
  validator = DEFAULT_PROPS.validator,
  errorMessage = 'Provided time is invalid',
  disabled = false,
  autofillMissingFields = false,
  style,
  // If PreciseTimePicker is incorrectly used as a form item,
  // extraProps will contain value and onChange. Handle with care
  ...extraProps
}: PreciseTimePickerProps) {
  const [currentValue, setCurrentValue] = useState<string>(time);
  const [currentDate, setCurrentDate] = useState<Dayjs | undefined>();

  const [showErrorMessage, setShowErrorMessage] = useState<boolean | null>(null);
  const [calculatedValues, setCalculatedValues] = useState<any>({});
  const [repeatBlur, setRepeatBlur] = useState<string | boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const inputMaskClass = `time-input-mask-${useId().replace(/:/g, '')}`;
  const pickerPanelClass = `time-picker-panel-${useId().replace(/:/g, '')}`;

  const isoDateFormat = `${americanFormat ? 'hh' : 'HH'}:mm:ss${americanFormat ? ' A' : ''}`;

  const { isValid, timeString } = calculatedValues;

  const ariaAttrs = useMemo(() => {
    return Object.fromEntries(Object.entries(extraProps).filter(([key]) => key.startsWith('aria-')));
  }, [JSON.stringify(extraProps)]);

  const mask = useMemo(() => {
    return getTimeMask(currentValue, withMilliseconds, withMicroseconds, americanFormat);
  }, [currentValue, withMilliseconds, withMicroseconds, americanFormat]);

  useEffect(() => {
    return addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key === 'Escape' || event.key === 'Tab') {
        setIsOpen(false);
      }
    });
  }, [setIsOpen]);

  useEffect(() => {
    return addEventListener('mousedown', (event: MouseEvent) => {
      const timeInputMask = document.querySelector('.' + inputMaskClass);
      const timePickerPanel = document.querySelector('.' + pickerPanelClass);

      const wasTimeInputMaskClicked = timeInputMask && timeInputMask.contains(event.target as Node);
      const wasTimePickerPanelClicked = timePickerPanel && timePickerPanel.contains(event.target as Node);

      if (!wasTimeInputMaskClicked && !wasTimePickerPanelClicked) {
        setIsOpen(false);
      }
    });
  }, [inputMaskClass, pickerPanelClass, setIsOpen]);

  useEffect(() => {
    setCurrentValue(time);
  }, [time, setCurrentValue]);

  useEffect(() => {
    const timeString = getAutofilledValue(currentValue, autofillMissingFields, americanFormat) ?? currentValue;
    const date = dayjs(timeString, isoDateFormat);
    setCurrentDate(date.isValid() ? date : undefined);
  }, [currentValue, isoDateFormat, setCurrentDate]);

  useEffect(() => {
    setCalculatedValues(getCalculatedTimeValues(currentValue, validator));
  }, [currentValue, validator]);

  const changeShowErrorState = (invokedByBlur?: boolean) => {
    showErrorMessage !== null || (invokedByBlur && !isValid) ? setShowErrorMessage(!isValid) : '';
  };

  // JSON.stringify prevent infinite loop with unmemoized validator prop function
  useEffect(() => {
    if (Object.values(calculatedValues).length) {
      changeShowErrorState();
    }
  }, [JSON.stringify(calculatedValues)]);

  /**
   * Update component state and propagate changes to the parent component
   * This should ONLY propagate user changes
   */
  const updateValueAndPropagateChanges = (updatedTime: string): void => {
    setCurrentValue(updatedTime);
    const newCalculatedValues = getCalculatedTimeValues(updatedTime, validator);
    if (Object.values(newCalculatedValues).length && !isEqual(newCalculatedValues, calculatedValues)) {
      onTimeChange(newCalculatedValues);
    }
  };

  // Mouse selecting the TimePicker time fields or 'Now' button
  const selectHandler = (selectedTime: Dayjs | undefined) => {
    if (selectedTime) {
      const suffix = withMilliseconds ? currentValue.slice(americanFormat ? 11 : 8) : '';
      updateValueAndPropagateChanges(selectedTime.format(isoDateFormat) + suffix);
    } else {
      console.error('[selectHandler] selectedTime is not defined.');
    }
  };

  // Keyboard input
  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateValueAndPropagateChanges(e.target.value.toUpperCase());
  };

  const inputBlurHandler = () => {
    const autofilledValue = getAutofilledValue(currentValue, autofillMissingFields, americanFormat);

    if (autofilledValue) {
      updateValueAndPropagateChanges(autofilledValue);
      setRepeatBlur(autofilledValue);
    } else {
      changeShowErrorState(true);
    }
  };

  useEffect(() => {
    if (repeatBlur && repeatBlur === timeString) {
      setRepeatBlur(false);
      inputBlurHandler();
    }
  }, [repeatBlur, timeString, setRepeatBlur]);

  return (
    <div className={classes.positionRelative}>
      <TimePicker
        className={classes.backgroundTimePicker}
        placeholder=""
        defaultValue={undefined}
        value={currentDate}
        use12Hours={americanFormat}
        onOk={() => setIsOpen(false)}
        onCalendarChange={selectHandler as any}
        popupClassName={pickerPanelClass}
        open={isOpen}
        disabled={disabled}
        {...ariaAttrs}
      />
      <div className={classnames(classes.timePickerContainer, inputMaskClass)} style={style}>
        <div className={classnames(classes.timePicker, disabled && classes.disabled, showErrorMessage && classes.invalid)}>
          <div className="ant-picker-input h-full">
            <InputMask
              placeholder={placeholder || 'Select time'}
              title=""
              className={classes.inputMask}
              size={18}
              autoComplete="off"
              value={currentValue}
              mask={mask}
              onClick={() => setIsOpen(true)}
              onChange={inputChangeHandler}
              onBlur={inputBlurHandler}
              disabled={disabled}
            />
            {showErrorMessage && <ExclamationCircleOutlined className={classes.exclamationMarkIcon} />}
          </div>
        </div>
        <Button style={{ left: '5px' }} onClick={() => setIsOpen(!isOpen)} disabled={disabled}>
          <ClockCircleOutlined />
        </Button>
      </div>
      {showErrorMessage && (
        <div className="ant-form-item-explain ant-form-item-explain-error">
          <div role="alert">{typeof errorMessage === 'function' ? errorMessage(calculatedValues) : errorMessage}</div>
        </div>
      )}
    </div>
  );
}
