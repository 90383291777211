import { Alert, Collapse, Input, Modal, Space } from 'antd';
import { ChangeEvent, useCallback } from 'react';
import { DashboardUpsert } from '../models/dashboard-upsert';

import { ExportOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import getUserGuidePath from '../../../utils/user-guide';

export interface DashboardUpsertDialogDelegate {
  onDashboardNameChanged(name: string): void;

  onDashboardUpsertDone(): void;

  onDashboardUpsertCancelled(): void;
}

export function DashboardUpsertDialog({ model, delegate }: { model: DashboardUpsert; delegate: DashboardUpsertDialogDelegate }) {
  const onOk = useCallback(() => delegate.onDashboardUpsertDone(), [delegate]);
  const onCancel = useCallback(() => delegate.onDashboardUpsertCancelled(), [delegate]);
  const onChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => delegate.onDashboardNameChanged(evt.target.value), [delegate]);

  const docsUrl = getUserGuidePath();

  const { branding } = window.CONFIG;

  return (
    <Modal
      title={model.id ? 'Edit Dashboard' : 'Create Dashboard'}
      maskClosable={false}
      open
      onOk={onOk}
      onCancel={onCancel}
      okButtonProps={{ disabled: model.state === 'saving' }}
      cancelButtonProps={{ disabled: model.state === 'saving' }}
      data-testid={model.id ? 'edit-dashboard-modal' : 'create-dashboard-modal'}
    >
      <Space direction="vertical" className="w-full">
        <div>{model.id ? 'Edit dashboard:' : 'Create a new dashboard:'}</div>
        <Input
          placeholder="Name"
          status={model.nameError ? 'error' : undefined}
          value={model.name}
          maxLength={40}
          allowClear
          onPressEnter={onOk}
          onChange={onChange}
          data-testid="dashboard-name-input"
        />
        {model.nameError && <Alert message={model.nameError} type="error" />}

        <Collapse
          className="infoCollapse spaced"
          items={[
            {
              key: '1',
              label: (
                <span data-testid="create-new-resource-cluster-info-title">
                  <InfoCircleOutlined style={{ marginRight: '5px' }} />
                  What are Dashboards?
                </span>
              ),
              children: (
                <div>
                  <p>
                    <strong>Dashboards</strong> are graphical container for a set of widgets that you can customize.
                    <br />
                    Each widget contains a preview of your Python web services, Streamlit or Voila apps.
                    <br />
                    <br />
                    Custom dashboards are a quick and efficient way to display and access your most commonly used {branding.longName}
                    elements.
                    <br />
                    You can build and retain multiple dashboards.
                  </p>
                  <p>
                    For further information, please refer to section &quot;<i>Building Dashboards</i>&quot; of the{' '}
                    <Link to={docsUrl} onClick={onCancel}>
                      documentation <ExportOutlined />
                    </Link>
                  </p>
                </div>
              ),
            },
          ]}
        ></Collapse>
      </Space>
    </Modal>
  );
}
