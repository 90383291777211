import React, { Suspense } from 'react';
import { Spinner } from '../Spinner';

export function suspendedLazyComponent<P>(Component: React.FunctionComponent<P>): React.FunctionComponent<JSX.IntrinsicAttributes & P> {
  // eslint-disable-next-line react/function-component-definition, react/display-name
  return (props: JSX.IntrinsicAttributes & P) => (
    <Suspense fallback={<Spinner />}>
      <Component {...props} />
    </Suspense>
  );
}
