import { EventSource } from 'eventsource';

import { handleError } from '../../services/error-notification';
import { DashboardApi, DashboardData } from './dashboard-api';
import { DashboardDetailParentController } from './dashboard-detail-controller';
import {
  asDashboardCreateUpdate,
  asDashboardRemoveUpdate,
  DashboardList,
  initialDashboardList,
  updateDashboards,
} from './models/dashboard-list';
import { initialDashboardRemove, saveDashboardRemove } from './models/dashboard-remove';
import { initialDashboardCreate, saveDashboardUpsert, updateDashboardName } from './models/dashboard-upsert';
import { DashboardNavDelegate } from './views/DashboardNav';

export class DashboardController implements DashboardNavDelegate, DashboardDetailParentController {
  private api = new DashboardApi();

  private model = initialDashboardList();

  private es: EventSource | undefined = undefined;

  constructor(private updateViewState: (_: DashboardList) => void) {}

  start() {
    if (!this.es) {
      void (async () => {
        this.es = await this.initEventSourceEndpoint();
      })();
    }
  }

  dispose() {
    this.es?.close();
  }

  private async restartInterval() {
    this.es?.close();

    this.es = await this.initEventSourceEndpoint();
  }

  private async initEventSourceEndpoint() {
    const es = await this.api.initDashboardInterval();

    es.addEventListener('message', (event) => {
      const value = typeof event.data === 'string' ? (JSON.parse(event.data) as DashboardData[]) : undefined;
      if (value) {
        this.update(updateDashboards(this.model, value));
      }
    });
    return es;
  }

  private getModel = (): DashboardList => this.model;

  private update = (model: DashboardList) => {
    this.model = model;
    this.updateViewState(model);
  };

  private updateCreate = asDashboardCreateUpdate(this.getModel, this.update);

  private updateRemove = asDashboardRemoveUpdate(this.getModel, this.update);

  onDashboardCreate(): void {
    this.updateCreate(initialDashboardCreate());
  }

  onDashboardNameChanged(name: string): void {
    this.updateCreate(updateDashboardName(this.model.create, name));
  }

  async onDashboardUpsertDone(): Promise<void> {
    handleError(await saveDashboardUpsert(this.api, this.model.create, this.restartInterval.bind(this), this.updateCreate));
  }

  onDashboardUpsertCancelled(): void {
    this.updateCreate(undefined);
  }

  onDashboardRemove(dashboardId: string): void {
    this.updateRemove(initialDashboardRemove(dashboardId));
  }

  async onDashboardRemoveSelected(remove: boolean): Promise<void> {
    if (!this.model.remove) {
      return;
    }

    if (remove) {
      handleError(await saveDashboardRemove(this.api, this.model.remove, this.restartInterval.bind(this), this.updateRemove));
    } else {
      this.updateRemove(undefined);
    }
  }

  async onDashboardUpdated(): Promise<void> {
    await this.restartInterval();
  }
}
