const superset = window.CONFIG.supersetDomain
  ? [
      {
        id: 'superset',
        name: 'Superset',
        subtitle: 'Visualize the Data Lake.',
        url: `https://${window.CONFIG.supersetDomain}`,
        devUrl: `https://${window.CONFIG.supersetDomain}`,
      },
    ]
  : [];

export const EXT_APPS_LIST = window.CONFIG.isMidasPeer
  ? [
      {
        id: 'mst-gitlab',
        name: 'GitLab',
        subtitle: 'Access MIDAS GitLab.',
        url: 'https://gitlab.midas.maystreet.com',
        devUrl: 'https://gitlab.midas.maystreet-dev.com',
      },
      {
        id: 'mst-rstudio',
        name: 'RStudio',
        subtitle: 'Access RStudio.',
        url: 'https://rstudio.midas.maystreet.com',
        devUrl: 'https://rstudio.midas.maystreet-dev.com',
      },
      {
        id: 'mst-dash-dashboards',
        name: 'Dash Dashboards',
        subtitle: 'Access Dashboards.',
        url: 'https://dash.midas.maystreet.com',
        devUrl: 'https://dash.midas.maystreet-dev.com',
      },
      ...superset,
    ]
  : [
      {
        id: 'mst-bcg',
        name: 'Bellport Config Generator',
        subtitle: 'Graphically generate and edit your Bellport configuration files.',
        url: 'https://apps.maystreet.com/bcg',
        devUrl: 'https://apps.shared-dev.maystreet.com/bcg',
      },
      ...superset,
    ];
